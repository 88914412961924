@import '../vars/breakpoints.less';

.hat-radio-group.form-control {
    width: 100%;
    padding: 0;
  }

.hat-radio-group {
    display: inline-flex;

    border: 1px solid #CFD8DC;
    border-radius: 4px;
    overflow: hidden;

    &__radio {
        position: relative;
        width: 100%;

        font-size: 16px;

        .only-desktop({
            &:hover {
                .hat-radio-group__radio-input:not([disabled]) ~ .hat-radio-group__radio-label {
                    background: #DADFE3;
                }
            }
        });



        &:not(:first-child) {
            border-left: 1px solid #CFD8DC;
        }
    }

    &__radio-label {
        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;
        padding: 12px 15px;

        background: #F1F5F6;
        color: #73808D;
        font-weight: 500;

        transition: all .2s cubic-bezier(.35, .9, .4, .9);
    }

    .hat-radio-group__radio-input:not([disabled]) ~ .hat-radio-group__radio-label {
        cursor: pointer;
    }

    &__radio-indicator {
        position: relative;
        display: block;
        margin-right: 13px;
        width: 24px;
        height: 24px;
        border: 1px solid #CFD8DC;
        border-radius: 100%;
        background: #FFF;
        -webkit-tap-highlight-color: transparent;

        &:after {
            content: '';
            position: absolute;
            top: 7px;
            left: 7px;
            width: 8px;
            height: 8px;
            border-radius: 100%;
            background: #3F65EF;
            transform: scale(0);
            transition: all .2s ease;
            opacity: 0.08;
            pointer-events: none;
        }
    }

    &__radio-input {
        display: none;

        &:checked + .hat-radio-group__radio-label {
            background: #FFF;
            color: #2E2F31;

            .hat-radio-group__radio-indicator {
                &:after {
                    transform: scale(1);
                    transition: all .2s cubic-bezier(.35, .9, .4, .9);
                    opacity: 1;
                }
            }
        }
    }

    &--dense {
        .hat-radio-group__radio-label {
            padding-left: 18.5px;
            padding-right: 18.5px;
        }
    }
}
