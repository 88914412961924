.b-landing__clients {
  margin-top: 100px;
  overflow: hidden;

  .body {
    max-width: 975px;
    margin-left: auto;
    margin-right: auto;
  }

  .clients__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 50px;

    @media (max-width: 767px) {
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 26px;
    }
  }

  .clients-header__title {
    font-size: 40px;
    font-weight: 900;
    line-height: 40px;

    @media (max-width: 767px) {
      font-size: 25px;
      font-weight: 900;
      line-height: 25px;
      margin-bottom: 15px;
    }
  }

  .clients-controls {
    display: flex;
    align-items: center;
  }

  .clients-controls__prev,.clients-controls__next {
    cursor: pointer;
  }

  .clients__item {
    &:not(.swiper-slide-active) {
      opacity: 0.3;
    }
  }

  .clients__item-container {
    width: 100%;
    border: 2px solid #E2E8FD;
    padding: 50px 60px;

    @media (max-width: 1050px) {
      padding: 50px 32px;
    }
    @media (max-width: 767px) {
      padding: 35px 22px;
    }
  }

  .clients__item-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;

    @media (max-width: 767px) {
      flex-direction: column;
      margin-bottom: 15px;
    }
  }

  .item-header__title {
    font-size: 26px;
    font-weight: 900;
    line-height: 31px;

    :first-child {
      margin-bottom: 10px;
    }

    @media (max-width: 767px) {
      font-size: 18px;
      font-weight: 900;
      line-height: 22px;
    }
  }

  .item-header__subtitle {
    font-size: 15px;
    font-weight: 400;
    line-height: 18px;
    color: #808289;

    @media (max-width: 767px) {
      font-size: 13px;
      font-weight: 400;
      line-height: 16px;
    }
  }

  .item-header__logo {
    img {
      height: fit-content;
      width: auto;
      max-height: 55px;
      max-width: 206px;
      object-fit: contain;
    }

    @media (max-width: 767px) {
      order: -1;
      margin-bottom: 27px;
    }
  }

  .clients__item-body {
    font-size: 18px;
    font-weight: 400;
    line-height: 25px;

    @media (max-width: 767px) {
      font-size: 13px;
      font-weight: 400;
      line-height: 18px;
    }

    p {
      &:last-child {
        margin-bottom: 40px;

        @media (max-width: 767px) {
          margin-bottom: 20px;
        }
      }

      &:not(:last-child) {
        margin-bottom: 25px;

        @media (max-width: 767px) {
          margin-bottom: 15px;
        }
      }

      &.item-body__question {
        margin-bottom: 10px;

        @media (max-width: 767px) {
          margin-bottom: 5px;
        }
      }
    }
  }

  .item-body__question {
    font-weight: 700;
  }

  .clients__item-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 2px solid #E2E8FD;
    padding-top: 25px;

    @media (max-width: 767px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .item-footer__case-link {
    font-size: 15px;
    font-weight: 500;
    line-height: 17px;
  }

  .item-footer__rates {
    display: flex;

    @media (max-width: 767px) {
      flex-direction: column;
    }
  }

  .footer-rate {
    display: flex;
    align-items: center;
    font-size: 13px;
    font-weight: 400;
    line-height: 14px;

    @media (max-width: 767px) {
      margin-bottom: 15px;

      &:last-child {
        margin-bottom: 20px;
      }
    }

    &:not(:last-child) {
      margin-right: 35px;
    }

    &:nth-child(1) {
      @media (max-width: 1050px) {
        .footer-rate__title {
          width: 59px;
        }
      }
      @media (max-width: 767px) {
        .footer-rate__title {
          width: unset;
        }
      }
    }
    &:nth-child(2) {
      @media (max-width: 1050px) {
        .footer-rate__title {
          width: 125px;
        }
      }
      @media (max-width: 767px) {
        .footer-rate__title {
          width: unset;
        }
      }
    }
    &:nth-child(3) {
      @media (max-width: 1050px) {
        .footer-rate__title {
          width: 80px;
        }
      }
      @media (max-width: 767px) {
        .footer-rate__title {
          width: unset;
        }
      }
    }
  }

  .footer-rate__icon {
    width: 24px;
    height: 24px;
    margin-right: 15px;
    background-size: 24px 24px;
    background-repeat: no-repeat;
    background-position: center;
  }

  .footer-rate__icon--good {
    background-image: url("data:image/svg+xml,%3Csvg width='26' height='26' viewBox='0 0 26 26' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16.9757 16.7448C16.473 17.255 15.8746 17.6612 15.2149 17.9402C14.5552 18.2192 13.8469 18.3655 13.1306 18.3708C12.4144 18.3761 11.7041 18.2402 11.0403 17.971C10.3765 17.7018 9.77224 17.3044 9.26202 16.8017M25 13C25 19.6274 19.6274 25 13 25C6.37258 25 1 19.6274 1 13C1 6.37258 6.37258 1 13 1C19.6274 1 25 6.37258 25 13Z' stroke='%233F65F1' stroke-width='2' stroke-linecap='round'/%3E%3Cpath d='M10.8183 10.2726C10.8183 11.1763 10.0857 11.909 9.18196 11.909C8.27822 11.909 7.54559 11.1763 7.54559 10.2726C7.54559 9.36886 8.27822 8.63623 9.18196 8.63623C10.0857 8.63623 10.8183 9.36886 10.8183 10.2726Z' fill='%233F65F1'/%3E%3Cpath d='M18.4547 10.2726C18.4547 11.1763 17.7221 11.909 16.8183 11.909C15.9146 11.909 15.182 11.1763 15.182 10.2726C15.182 9.36886 15.9146 8.63623 16.8183 8.63623C17.7221 8.63623 18.4547 9.36886 18.4547 10.2726Z' fill='%233F65F1'/%3E%3C/svg%3E%0A");
  }

  .footer-rate__icon--perfect {
    background-image: url("data:image/svg+xml,%3Csvg width='26' height='26' viewBox='0 0 26 26' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.8981 10.3314C10.6467 10.0763 10.3475 9.87317 10.0176 9.73367C9.68778 9.59418 9.33366 9.52102 8.97552 9.51838C8.61738 9.51574 8.26223 9.58367 7.93034 9.71828C7.59845 9.8529 7.29632 10.0516 7.04121 10.3029M18.9699 10.3314C18.7185 10.0763 18.4193 9.87317 18.0895 9.73367C17.7596 9.59418 17.4055 9.52102 17.0473 9.51838C16.6892 9.51574 16.334 9.58367 16.0022 9.71828C15.6703 9.8529 15.3681 10.0516 15.113 10.3029M16.9759 16.7448C16.4731 17.255 15.8748 17.6612 15.2151 17.9402C14.5553 18.2192 13.8471 18.3655 13.1308 18.3708C12.4146 18.3761 11.7042 18.2402 11.0405 17.971C10.3767 17.7018 9.77243 17.3044 9.26221 16.8017M25 13C25 19.6274 19.6274 25 13 25C6.37258 25 1 19.6274 1 13C1 6.37258 6.37258 1 13 1C19.6274 1 25 6.37258 25 13Z' stroke='%233F65F1' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E%0A");
  }

  .footer-rate__title {

  }

  .clients__container-wrapper {
    width: calc(3*975px + 80px);
    margin-left: -1015px;

    @media (max-width: 1050px) {
      width: 100%;
      margin-left: unset;
    }
  }
}
