@import "vars.less";
@import "../../parts/fancybox";

@import "_forms.less";

:root {
  --primary: #3f65f1;
  --body-color: #000000;
  --light-color: #f5f6fa;
  --light-accent: #f4f6fe;
}

* {
  scroll-behavior: smooth;
}

body {
  color: #000000;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  line-height: 1.2;
  background-color: var(--light-color);
}

.bold {
  font-weight: bold;
}

.list {
  list-style: none;

  li {
    display: flex;
    margin-bottom: 12px;
    line-height: 1.3;

    &:before {
      content: "";
      display: block;
      width: 8px;
      height: 8px;
      transform: rotate(45deg);
      background: #b0c0ff;
      margin-right: 20px;
      margin-top: calc(1.3em - 14px);
      flex-shrink: 0;
      margin-left: 2px;
    }
  }

  &--inner {
    li {
      display: block;

      &:before {
        display: inline-block;
        vertical-align: top;
        margin-top: calc(1.3em - 15px);
      }
    }
  }
}

.b-landing__modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 366px;
  background: #ffffff;
  box-shadow: 0px 5px 20px rgba(115, 128, 141, 0.4);
  border-radius: 9px;
  padding: 30px 15px;
  z-index: 9;
  text-align: center;
  font-size: 17px;

  p {
    margin-bottom: 30px;
  }

  &--fixed {
    position: fixed;
  }
}

.buy-rate-form {
  &__label {
    font-size: 13px;
    color: #808289;
    margin-bottom: 9px;
  }
}

.b-landing {
  //overflow-x: hidden;
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  .auth-link {
    width: 35px;
    height: 35px;
    padding: 0;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-left: 16px;
  }

  &__content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  .contact-form {
    margin-top: 50px;
  }

  &__player {
    margin-top: 0px;
    margin-bottom: 134px;

    .player {
      position: relative;
      &__loader {
        position: absolute;
        justify-content: center;
        align-items: center;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgb(255, 255, 255);
        color: #3f65f1;
        display: none;
        z-index: 3;
      }

      &--loading {
        .player__loader {
          display: flex;
        }
      }

      video {
        width: 100%;
      }
    }
  }

  .item.item_small.d-none.d-lg-none.d-md-inline-flex {
    margin-right: 30px;
    font-size: 14px;
    font-weight: 500;
  }

  a {
    color: var(--primary);
  }

  .form-group_text-link {
    margin-top: -20px;
  }

  a:active,
  a:focus {
    text-decoration: none !important;
  }

  .nav__pagination {
    display: none;
  }

  .icon-n-2 {
    transform: translate(-5px, 26px);
  }

  .icon-n-3 {
    margin-top: -20px;
  }

  .icon-n-4 {
    margin-top: -10px;
    margin-left: -20px;
    margin-right: 20px;
  }

  &--initializing {
    visibility: hidden;
  }

  font-family: Roboto, sans-serif;
  color: var(--body-color);
  transition: visibility 0.5s ease-in-out;

  &__content {
    width: 100%;
    margin: 0 auto;
    max-width: 1240px;
    padding: 0 30px;
  }

  &__header {
    padding: 22px 0 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .btn-primary-outline {
      height: 35px;
      font-size: 13px;
    }
  }

  &__menu {
    margin-right: 30px;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .item {
      margin-right: 25px;
      font-size: 15px;
      transition: color 0.2s ease-in-out;
      color: var(--body-color);

      &_small {
        color: var(--primary);
        font-size: 14px;
        font-weight: 500;
        margin-right: 0;
        //margin-left: 30px;
      }

      &:hover {
        --primary: #3553c1;
        color: var(--primary);
        text-decoration: none;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__logo {
    .icon-foquz-logo(#000000);
    background-image: url("/img/logo-ru.svg");
  }

  &__some-landing {
    .h1 {
      font-weight: 900;
      font-size: 80px;
      line-height: 1.2;
      color: var(--body-color);
      margin-bottom: 3px;
      padding: 0;
    }

    .h2 {
      padding: 0;
      font-weight: 300;
      font-size: 35px;
      line-height: 1.2;
      color: var(--body-color);
      margin-bottom: 59px;
    }

    .btn-primary {
      margin-bottom: 24px;
    }

    .btn-primary-outline.btn {
      padding: 0 27px;
      margin-left: 15px;
      height: 50px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      font-size: 17px;
      font-weight: 500;
      border-radius: 5px;
      margin-bottom: 24px;
    }

    .description {
      font-size: 15px;
      line-height: 1.2;
      color: var(--body-color);
      max-width: 80%;
    }

    .title-part {
      margin-top: -23px;
    }
  }

  #mainAnimationContainer {
    //min-height: 475px;
    height: 475px;
    overflow: hidden;
    position: relative;

    svg {
      width: 220% !important;
      height: 220% !important;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) !important;
    }
  }

  &__benefits {
    margin-top: 0px;

    .title {
      font-weight: 900;
      font-size: 40px;
      line-height: 1.175;
      text-align: center;
      color: var(--body-color);
      margin-bottom: 80px;
    }

    .item {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      margin-bottom: 70px;

      .icon {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        width: 135px;
        margin-right: 72px;

        svg {
          transform: translate(20px, -7px);

          &.n4 {
            transform: translate(20px, -20px);
          }
        }
      }

      .text {
        width: calc(100% - (135px + 50px));
        padding-right: 60px;
        max-width: 320px;
        font-size: 15px;
        line-height: 1.3;

        .strong {
          line-height: 1.1;
          font-weight: bold;
          margin-bottom: 12px;
          font-size: 18px;
        }
      }
    }
  }

  &__form {
    padding-top: 50px;
    margin: 0 auto;
    position: relative;
    opacity: 0.99;
    width: 100%;

    .title {
      position: relative;
    }

    form {
      position: relative;
    }

    .svg-wrapper {
      position: absolute;
      height: 1213px;
      top: 50%;
      transform: translateY(-60%);
      width: 100%;
      overflow: hidden;
      pointer-events: none;
    }

    .animation-svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -45%);
      z-index: -1;
      margin-top: 120px;

      path {
        animation: formAnimation 6s alternate infinite;
      }
    }

    .title {
      font-weight: 900;
      font-size: 35px;
      line-height: 1;
      text-align: center;
      color: var(--body-color);
      margin-bottom: 15px;
    }

    .inner {
      width: 366px;
      margin: 0 auto;
      font-size: 15px;
    }

    p {
      width: 100%;
      margin-bottom: 20px;
    }

    .hint {
      position: absolute;
      width: 100%;
      text-align: center;
      left: 0;
      top: -30px;
    }

    .toggler-group {
      p {
        margin: 27px 0;
      }
    }

    .form-group {
      margin-bottom: 40px;
      position: relative;
    }
  }

  &__burger {
    display: none;
    .icon-burger(#3f65f1);
    width: 24px;
    height: 14px;
    margin-left: 35px;

    &_close {
      .icon-burger-close(#3f65f1);
      transform: translateX(6px);
    }
  }

  .single-phone {
    display: none;
  }

  &__dropdown {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 12;
    background-color: #ffffff;
    padding: 0 30px;

    .b-landing__header {
      padding-top: 23px;
    }

    .menu {
      div {
        margin-bottom: 25px;

        a {
          font-size: 20px;
          font-weight: 900;
          color: var(--body-color);

          &:hover {
            color: #3553c1;
          }

          &:active,
          &:focus {
            color: #3553c1;
          }

          &.small {
            font-size: 15px;
            font-weight: normal;
            color: var(--primary);
          }

          &.tel {
            color: var(--primary);

            &:hover {
              color: #3553c1;
            }

            &:active,
            &:focus {
              color: #3553c1;
            }
          }

          &.btn {
            color: var(--primary);
            font-size: 15px;
            margin: 5px 0;
            font-weight: 500;
            height: 40px;

            &:hover {
              color: var(--primary);
            }

            &:active,
            &:focus {
              color: var(--primary);
            }
          }
        }
      }
    }
  }
}

.body_landing {
  background-color: #ffffff;
}

@keyframes formAnimation {
  0% {
    d: path(
      "M934.129 860.916C873.616 930.722 781.236 967.227 689.219 975.448C607.265 982.769 539.071 924.159 459.473 903.32C368.067 879.39 257.031 910.403 189.265 844.558C119.028 776.313 88.2568 662.337 114.298 567.931C139.268 477.404 250.049 448.179 316.816 382.142C371.314 328.239 396.56 242.615 468.883 217.222C542.47 191.384 618.65 232.788 694.85 249.408C785.452 269.17 892.739 255.597 955.941 323.455C1021.1 393.408 1021.79 500.755 1017.92 596.272C1014.07 691.046 996.259 789.245 934.129 860.916Z"
    );
  }

  50% {
    d: path(
      "M951 736.001C890.487 805.807 835.028 777.78 743.011 786C661.057 793.321 578.598 862.839 499 842C407.594 818.07 286.766 907.845 219 842C148.763 773.755 94.9593 638.406 121 544C145.971 453.473 220.233 414.037 287 348C341.498 294.098 436.677 287.394 509 262C582.587 236.163 666.811 273.38 743.011 290C833.613 309.762 875.798 258.142 939 326C1004.15 395.953 1090.89 484.484 1087.01 580.001C1083.16 674.775 1013.13 664.33 951 736.001Z"
    );
  }

  100% {
    d: path(
      "M922 714C861.487 783.806 812.017 783.78 720 792C638.045 799.321 533.597 824.839 453.999 804C362.593 780.069 265.765 837.845 197.999 772C127.763 703.755 183.958 600.406 209.999 506C234.97 415.473 175.232 376.037 241.999 310C296.497 256.098 415.676 277.394 487.999 252C561.586 226.163 621.81 245.38 698.01 262C788.612 281.762 810.797 242.142 873.999 310C939.153 379.953 961.876 440.483 957.999 536C954.153 630.774 984.13 642.329 922 714Z"
    );
  }
}

@media (max-width: @breakpointXl) {
  .b-landing {
    &__menu {
      display: none;
    }
    &__benefits {
      .item {
        .text {
          max-width: none;
        }
      }
    }
  }
}

@media (min-width: 768px) and (max-width: @breakpointXl) {
  .b-landing {
    // overflow: hidden;

    &__player {
      margin-top: 60px;
      margin-bottom: 80px;
    }

    .auth-link {
      display: inline-flex;
    }

    .single-phone {
      display: inline-flex;
      font-size: 15px;
      margin-right: 17px;
    }

    .btn-primary-outline {
      font-size: 14px;
      padding: 0 15px;
    }

    &__burger {
      display: block;
    }

    &__header {
      padding-bottom: 64px;
    }

    .b-landing__some-landing .h2 {
      margin-bottom: 35px;
    }

    .icon-n-4 {
      margin-top: -22px;
    }

    &__benefits {
      margin-top: 0px;

      .title {
        text-align: left;
        margin-bottom: 52px;
      }

      .item .text .strong {
        margin-bottom: 14px;
      }

      .item {
        margin-bottom: 52px;

        &_n3 {
          margin-bottom: 38px;
        }

        &_n4 {
          margin-bottom: 50px;
        }

        .text {
          padding-right: 80px;
          max-width: none;
        }
      }
    }

    .b-landing__benefits .item .icon {
      margin-right: 50px;
    }

    &__form {
      padding-top: 50px;

      .svg-wrapper {
        transform: translateY(-60%);
      }

      .inner {
        width: 340px;
      }

      .form-group {
        margin-bottom: 36px;
      }
    }
  }
}

@media (max-width: 767px) {
  .b-landing {
    &__player {
      margin-top: 35px;
      margin-bottom: 35px;
    }
  }

  .b-landing .form-group_text-link {
    margin-top: -5px;
  }

  .b-landing__some-landing .h1 {
    font-size: 40px;
  }

  .b-landing__some-landing .btn-primary {
    margin: 0;
  }

  .b-landing__some-landing .description {
    max-width: 100%;
    font-size: 13px;
    padding-top: 10px;
  }

  .b-landing {
    // overflow: hidden;

    &__logo {
      flex-shrink: 1;
    }
    &__content {
      padding: 0 20px;
    }

    .single-phone {
      display: none;
    }

    .btn-primary-outline {
      font-size: 14px;
      padding: 0 15px;
    }

    &__menu {
      display: none;
    }

    &__burger {
      display: block;
      margin-left: 10px;
      flex-shrink: 0;
    }

    &__header {
      padding-bottom: 60px;
      padding-top: 15px;

      .justify-content-end {
        .btn {
          display: none;
        }
      }
    }

    .btn-primary {
      width: 100%;
      font-size: 15px;
      height: 40px;
    }

    .b-landing__some-landing .h2 {
      margin-bottom: 19px;
      font-size: 20px;
    }

    &__some-landing {
      .btn.btn-primary-outline {
        margin-left: 0;
        margin-top: 12px;
        display: flex;
        margin-bottom: 0;
        font-size: 15px;
        height: 40px;
      }
    }

    &__benefits {
      margin-top: 10px;

      .title {
        text-align: center;
        font-size: 25px;
        margin-bottom: 52px;
      }

      .item .text .strong {
        margin-bottom: 14px;
      }

      .item {
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .icon {
          margin-right: 0 !important;
        }

        .text {
          padding-right: 0;
          width: 100%;
          text-align: center;
          padding-top: 10px;
          max-width: none;

          .strong {
            margin-bottom: 7px;
          }
        }

        &_n3 {
          margin-bottom: 38px;
        }

        &_n4 {
          margin-bottom: 50px;
        }
      }
    }

    .b-landing__benefits .item .icon {
      margin-right: 50px;
    }

    &__form {
      padding-top: 10px !important;

      .contact-form {
        margin-top: 30px;
      }

      .svg-wrapper {
        transform: translateY(-55%);
      }

      .form-group {
        margin-bottom: 24px;
      }

      .title {
        max-width: 260px;
        margin-left: auto;
        margin-right: auto;
      }

      .toggler-group {
        p {
          margin: 20px 0;
          font-size: 13px;
        }
      }

      .inner {
        width: 340px;
      }
    }
  }

  .b-landing__form {
    padding-top: 80px;
    width: 100%;
  }

  .mt-lg {
    margin-top: 60px;
  }

  .b-landing__form .title {
    font-size: 25px;
    margin-bottom: 10px;
  }

  .b-landing__form p {
    width: 100%;
    margin-bottom: 24px;
    margin-left: 0;
  }

  .form-control {
    height: 40px;
  }

  .b-landing__form .form-group {
    margin-bottom: 24px;
  }

  .form-group_text-link {
    margin-top: 0;
  }

  .b-landing__form .hint {
    top: -20px;
  }

  .b-landing__form .inner {
    width: 100%;
  }

  .sub-level {
    padding-top: 25px;
    width: 100%;

    a {
      margin-right: 17px;
    }
  }

  path {
    animation: none !important;
  }

  .b-landing__dropdown {
    padding: 0 20px;

    .b-landing__header {
      padding-top: 8px;
      padding-bottom: 40px;
    }
  }

  .nav__pagination {
    display: flex !important;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: absolute;
    margin-top: -20px;

    .i {
      margin: 0 7px;
      width: 10px;
      height: 10px;
      transition: all 0.25s ease-in-out;
      background-color: #d9e0fc;
      border-radius: 50%;

      &.active {
        background-color: var(--primary);
      }
    }
  }

  .b-landing .b-landing__benefits .item .icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .b-landing__benefits .item .icon svg,
  .b-landing__benefits .item .icon svg.n4 {
    transform: none;
  }

  .b-landing .icon-n-4 {
    margin-left: 0;
    margin-right: 0;
  }

  .b-landing__benefits .title {
    margin-bottom: 38px;
  }

  .b-landing__benefits .item .text {
    padding-top: 20px;
  }

  .b-landing__benefits .item {
    margin-bottom: 30px;
  }

  .b-landing__form .animation-svg {
    height: 850px;
    margin-top: 30px;
  }

  .b-landing .invalid-feedback {
    margin: -13px 0 35px !important;
  }
}
.modal.modal-with-base-backdrop {
  padding: 0 !important;
}

.modal .buy-rate-modal-dialog {
  .invalid-feedback {
    margin-top: -19px !important;
  }
  .modal-content {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 16px;
  }
  .modal-header {
    padding-top: 45px !important;
  }
  .modal-title {
    font-size: 30px !important;
  }

  .form-control {
    border-color: #c4c4c4;

    &.is-invalid {
      border-color: #ff002e;
    }
  }

  .close {
    opacity: 0.5 !important;
    right: -5px;
    outline: none !important;
    box-shadow: none !important;
  }

  .hat-radio-group {
    border-color: #c4c4c4;
    &__radio-label {
      font-size: 15px;
      font-weight: normal;
      background: transparent;
      padding-left: 15px;
      padding-right: 15px;
    }
    &__radio-indicator {
      width: 20px;
      height: 20px;
      margin-right: 10px;
      &:after {
        top: 5px;
        left: 5px;
        height: 8px;
        width: 8px;
      }
    }
    .hat-radio-group__radio-input:checked + .hat-radio-group__radio-label {
      background: #f1f3f9;
    }
    .hat-radio-group__radio:not(:first-child) {
      border-left: 1px solid #c4c4c4;
    }
  }
}

@media (max-width: 767px) {
  .modal .buy-rate-modal-dialog {
    margin: auto;
    height: 100%;
    width: 100%;

    .invalid-feedback {
      margin-top: -13px !important;
    }

    .modal-content {
      align-self: stretch;
      height: 100%;
      display: flex;
      max-height: none;
      border-radius: 0;
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 0;
    }

    .modal-header {
      padding-top: 27px !important;
    }

    .modal-body,
    .modal-header {
      padding-left: 20px !important;
      padding-right: 20px !important;
    }

    .modal-title {
      font-size: 25px !important;
    }

    .hat-radio-group {
      &__radio-label {
        padding-left: 10px;
        padding-right: 10px;
        font-size: 13px;
      }
    }

    .btn-primary {
      height: 40px;
      font-size: 15px;
    }

    .close {
      right: 20px;
    }
  }
}

.tooltip {
  &--white {
    position: relative;
    .tooltip-inner {
      background: white;
      color: #2e2f31;
      padding: 15px 20px 15px 20px;
      border-radius: 9px;
      box-shadow: 0px 5px 20px rgba(115, 128, 141, 0.4);
      font-size: 13px;
      line-height: 1.2;
      text-align: left;
      pointer-events: none;
      max-width: 220px;
    }

    &.show {
      opacity: 1;
    }

    .close {
      display: none;
      position: absolute;
      top: 0px;
      right: 0px;
      padding: 10px;
    }
  }
}

@media (max-width: @breakpointXl) {
  .tooltip {
    &--white {
      .tooltip-inner {
        padding-right: 40px;
      }
      .close {
        display: block;
      }
    }
  }
}

@import "./layout.less";
@import "./typography.less";
@import "./solutions.less";
@import "./features.less";

@import "./modules.less";
@import "./support.less";
@import "./about.less";
@import "./partners.less";
@import "./footer.less";
@import "ugc.less";
@import "clients.less";
