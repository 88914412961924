.f-border-left {
  border-left: 1px solid @f-color-border!important;
}
.f-border-right {
  border-right: 1px solid @f-color-border!important;
}
.f-border-top {
  border-top: 1px solid @f-color-border!important;
}
.f-border-bottom {
  border-bottom: 1px solid @f-color-border!important;
}
.f-border-x {
  border-left: 1px solid @f-color-border!important;
  border-right: 1px solid @f-color-border!important;
}
.f-border-y {
  border-top: 1px solid @f-color-border!important;
  border-bottom: 1px solid @f-color-border!important;
}
.f-border {
  border: 1px solid @f-color-border!important;
}


.f-transform {
  &--transition {
    transition: transform 250ms;
  }
  &-mirror-x {
    transform: scaleX(-1);
  }
  &-mirror-y {
    transform: scaleY(-1);
  }
  &-rotate-180 {
    transform: rotate(-180deg);
  }
}

.cursor-zoom-in {
  cursor: zoom-in!important;
}

.cursor-not-allowed {
  cursor: not-allowed!important;
}

.cursor-default {
  cursor: default!important;
}


.circle {
  display: inline-block;
  vertical-align: middle;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: currentColor;
}
