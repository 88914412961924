@step: 4px;
@customStep: 5px;

.margin(@px, @side) {
  & when (@side = a), (@side = all), (@side = none) {
    margin: 1px * @px;
  }
  & when (@side = l) {
    margin-left: 1px * @px;
  }
  & when (@side = r) {
    margin-right: 1px * @px;
  }
  & when (@side = t) {
    margin-top: 1px * @px;
  }
  & when (@side = b) {
    margin-bottom: 1px * @px;
  }
  & when (@side = x) {
    margin-left: 1px * @px;
    margin-right: 1px * @px;
  }
  & when (@side = y) {
    margin-top: 1px * @px;
    margin-bottom: 1px * @px;
  }
}

.padding(@px, @side) {
  & when (@side = a), (@side = all), (@side = none) {
    padding: 1px * @px;
  }
  & when (@side = l) {
    padding-left: 1px * @px;
  }
  & when (@side = r) {
    padding-right: 1px * @px;
  }
  & when (@side = t) {
    padding-top: 1px * @px;
  }
  & when (@side = b) {
    padding-bottom: 1px * @px;
  }
  & when (@side = x) {
    padding-left: 1px * @px;
    padding-right: 1px * @px;
  }
  & when (@side = y) {
    padding-top: 1px * @px;
    padding-bottom: 1px * @px;
  }
}

@sides: t, b, l, r, x, y, a;
@spacingStep: 5;
@iterationCount: 10;

.spacing-iteration(@iteration, @i: length(@sides)) when (@i > 0) {
  @side: extract(@sides, @i);
  @spacing: @iteration * @spacingStep;
  .m@{side}-@{spacing}p {
    .margin(@spacing, @side);
  }
  .m@{side}-n@{spacing}p {
    .margin(-1 * @spacing, @side);
  }
  .p@{side}-@{spacing}p {
    .padding(@spacing, @side);
  }
  .p@{side}-n@{spacing}p {
    .padding(-1 * @spacing, @side);
  }

  .spacing-iteration(@iteration, @i - 1);
}

.spacing-loop(@iteration) when (@iteration <= @iterationCount) {
  .spacing-iteration(@iteration);

  .spacing-loop(@iteration + 1);
}

.spacing-loop(1);
