.b-landing__support {
  margin-top: 70px;

  .title {
    font-weight: 900;
    font-size: 40px;
    line-height: 100%;
    color: #000000;
    margin-bottom: 48px;
  }

  .body {
    max-width: 975px;
    margin-left: auto;
    margin-right: auto;

    font-size: 17px;
    line-height: 1.6;
  }

  p,
  .p {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .support-wrapper {
    display: flex;
  }

  .support-action {
    height: 50px;
    width: 250px;
    padding: 0 30px;
    font-size: 17px;
    font-weight: 500;

    margin-top: 18px;
  }

  &-left {
    max-width: 570px;
    flex-grow: 1;
    padding-right: 40px;
  }

  &-right {
    width: 266px;
    flex-shrink: 0;

    .link {
      font-style: normal;
      font-weight: bold;
      font-size: 30px;
      line-height: 41px;
    }

    .section {
      margin-bottom: 16px;
    }

    .section__social-contacts {
      margin-top: -4px;
      margin-bottom: 7px;
    }

    .subtitle {
      font-size: 18px;
      font-weight: 700;
    }

    .social-contact--tg {
      .icon-link-tg--lg(#3f65f1);
    }
    
    .social-contact--wa {
      .icon-link-wa--lg(#3f65f1);
      margin-right: 13px;
    }

    .social-contact:hover {
      text-decoration: none;
    }
  }
}

.support-block {
  display: flex;
  margin-bottom: 28px;
  h3 {
    font-size: 26px;
    font-weight: 900;
    margin-bottom: 12px;
    line-height: 1.2;
  }
  &__icon {
    width: 60px;
    flex-shrink: 0;
  }

  &__list {
    font-size: 17px;
  }
}

.support-note {
  padding-left: 60px;
  font-size: 18px;
  line-height: 1.3;
  max-width: 500px;
}

@media (max-width: 767px) {
  .b-landing__support {
    margin-top: 40px;
    .btn-primary-outline {
      width: 100%;
    }

    .support-wrapper {
      display: block;
    }
    .title {
      font-size: 25px;
      margin-bottom: 30px;
    }

    .support-block {
      margin-bottom: 24px;
    }

    .support-action {
      padding: 0 40px;
      font-weight: 500;
      margin-top: 10px;

      height: 40px;
      width: 100%;
      max-width: 300px;
      font-size: 15px;
    }

    &-left {
      max-width: none;
      padding-right: 0;
    }

    &-right {
      width: 100%;
      .section {
        margin-bottom: 20px;
      }
      .link {
        font-size: 25px;
        line-height: 1;
      }

      .subtitle {
        font-size: 15px;
      }
    }
  }
  .support-note {
    margin-bottom: 30px;
    padding-left: 0px;
    font-size: 15px;
  }

  .support-block {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 14px;
    }
    &__icon {
      margin-top: -2px;
      width: 45px;
    }
    h3 {
      font-size: 18px;
      height: 35px;
      display: flex;
      align-items: center;
      padding-left: 7px;
    }
    &__list {
      margin-left: -22px;
      font-size: 15px;

      li:last-child {
        margin-bottom: 0;
      }
    }
  }
}
