.fancybox-button--rotate {
	svg {
		width: 17px;
		height: 16px;
		margin: auto;

		fill: none;
	}

	path:nth-child(1) {
		stroke: currentColor;
		stroke-width: 2;
		stroke-linecap: round;
		stroke-linejoin: round;
	}

	path:nth-child(2) {
		fill: none;
		stroke: currentColor;
		stroke-width: 2;
		stroke-linecap: round;
		stroke-linejoin: round;
	}
}

.fancybox-navigation  {
	.fancybox-button {
		width: 38px;
		height: 38px;
		padding: 0!important;
		top: calc(50% - 20px);
	}
}
