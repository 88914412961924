.btn {
  font-family: "Roboto", sans-serif !important;
  font-weight: 500 !important;
}

.btn-primary-outline {
  color: var(--primary) !important;
  background-color: transparent;
  border: 2px solid var(--primary) !important;
  border-radius: 4px;
  font-family: Roboto, sans-serif;
  padding: 0 25px;
  height: 37px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  outline: none !important;
  box-shadow: none !important;
  transition: all 0.25s ease-in-out;

  &:hover {
    border-color: #3553c1 !important;
    color: #3553c1 !important;
  }
}

.btn-primary {
  padding: 0 27px;
  height: 50px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: var(--primary);
  color: #fff !important;
  outline: none !important;
  box-shadow: none !important;
  font-size: 17px;
  font-weight: 500;
  border-radius: 5px;

  &:hover {
    background-color: #3553c1 !important;
  }

  &[disabled] {
    background-color: #3f65f1 !important;
    opacity: 0.5;
  }
}

.btn-primary--invert {
  color: var(--primary) !important;
  background-color: #fff !important;

  &:hover {
    background-color: #fff !important;
    color: #3553c1 !important;
  }
}

.toggler {
  display: flex;
  padding: 1.5px;
  border-radius: 5px;
  background: var(--primary);

  .btn {
    flex-grow: 1;
    margin: 1.5px;
    font-size: 15px !important;
    padding: 10px 5px;
    height: auto !important;
    line-height: 1;
    min-height: 44px;
  }
}

.invalid-feedback {
  text-align: left;
  background-image: none !important;
  margin: -35px 0 35px !important;
  font-size: 13px;
  color: #ff002e;
  padding-left: 0 !important;
}

.form-control {
  box-shadow: none !important;
  padding-left: 15px;
  padding-right: 15px;
  background-color: #ffffff !important;

  &.is-invalid {
    background-color: white !important;
    border-color: #ff002e;
    .icon-input-error(#ff002e);
    background-size: 22px 22px !important;
    background-repeat: no-repeat !important;
    background-position: calc(100% - 12px) 50% !important;
  }
}

@keyframes spinner-rotation {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

.send-button {
  .btn-pending {
    display: none;
  }

  &.pending {
	cursor: default;
    span.text {
      visibility: hidden;
    }
    .btn-pending {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 2;
	  background: rgba(255, 255, 255, 0.6);

      svg {
        width: 40px;
        height: 40px;
        animation: spinner-rotation 2s steps(8, end) infinite;
      }
    }
  }
}
