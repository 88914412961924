.solutions {
  .text {
    max-width: 975px;
    margin-bottom: 40px;
    font-size: 17px;
  }

  &-folders {
    display: flex;
    flex-wrap: wrap;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 20px;

    .solutions-folder {
      margin-right: 40px;
      margin-bottom: 20px;

      a {
        color: var(--primary);
        text-decoration: none;
      }

      &.active {
        a {
          color: black;
        }
      }
    }
  }

  .solution {
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 40px;

    &__image {
      height: 200px;
      position: relative;
      display: flex;
      align-items: flex-end;

      text-decoration: none;

      background-color: black;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #000000;
        opacity: 0.7;
        z-index: 2;
        pointer-events: none;
      }
    }

    &__name {
      position: relative;
      z-index: 3;
      font-size: 18px;
      font-weight: 900;
      line-height: 1.3;
      color: white;
      padding: 20px;
    }

    &__info {
      padding: 20px;
      background: #f4f6fe;
      font-size: 13px;
      color: black;
      line-height: 1.3;
    }

    &__description {
      height: 2.6em;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: 16px;
    }

    &__footer {
      display: flex;
      align-items: center;
      .solution__use {
        font-size: 13px;
      }
      .solution__preview {
        &,
        &:hover {
          text-decoration: none;
        }

        font-weight: 500;
      }
    }
  }



  .b-landing__footer {
    padding-top: 70px;
  }

  @media screen and (max-width: @breakpointXl) {
    .b-landing__header {
      padding-bottom: 35px;
    }
  }

  @media screen and (min-width: @breakpointLg + 1px) {
    iframe {
      height: 100%!important;
    }
  }

  @media screen and (max-width: @breakpointLg) {
    .b-landing__footer {
      padding-top: 30px;
    }

    .text {
      margin-bottom: 30px;
    }
  }


  @media (max-width: @breakpointSm) {
    .b-landing__header {
      padding-bottom: 25px;
    }

    .b-landing__footer {
      padding-top: 40px;
    }

    .text {
      font-size: 13px;
      margin-bottom: 20px;
    }
    &-folders {
      font-size: 13px;

      .solutions-folder {
        margin-right: 20px;
        margin-bottom: 14px;
      }
    }

    .solution {
      margin-bottom: 20px;
    }

    
  }
}
