.f-label {
  height: 24px;
  min-width: 24px;
  padding: 4px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 13px;
  border-radius: 12px;
  color: @f-color-service;

  &-primary {
    background-color: @f-color-primary;
    color: @f-color-primary--contrast;
  }
}
