.f-card {
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 4px 4px 7px 7px;
  border: none;

  &--min-height {
    min-height: 540px;
  }

  &--shadow {
    box-shadow: 0 2px 4px @f-color-shadow;
  }

  &__grow {
    flex-grow: 1;
  }

  &__inner {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  &__section {
    padding: 20px var(--foquz-side-offset);
  }

  &__full {
    margin-left: calc(-1 * var(--foquz-side-offset));
    margin-right: calc(-1 * var(--foquz-side-offset));
  }

  &__divider {
    border-bottom: 1px solid @f-color-border;
  }

  &__footer {
    background: @f-color-bg--light;
    position: relative;
    margin-top: 50px;
    padding: 20px var(--foquz-side-offset);
    border-radius: 0 0 7px 7px;

    &--ghost {
      background: transparent;
      height: 80px;
      margin-top: 20px;
    }
  }

  &-row {
    display: flex;
    padding-left: calc(var(--foquz-side-offset) - 10px);
    padding-right: calc(var(--foquz-side-offset) - 10px);
    margin-left: calc(-1 * var(--foquz-side-offset));
    margin-right: calc(-1 * var(--foquz-side-offset));

    &--wrap {
      flex-wrap: wrap;
    }

    &__block {
      padding-left: 10px;
      padding-right: 10px;

      &--right {
        margin-left: auto;
      }
    }
  }

  &--lg {
    border-radius: 9px;

    .f-card__footer {
      border-radius: 0 0 9px 9px;
    }
  }

  &--sm {
    border-radius: 4px;
  }

  &-filters {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding-bottom: 8px;
    padding-left: 5px;
    padding-right: 5px;

    &__item {
      margin-left: 15px;
      margin-right: 15px;
      margin-bottom: 12px !important;
    }

    &__actions {
      margin-left: auto;
      padding-right: 15px;
      padding-left: 15px;
      margin-bottom: 12px;
      display: flex;
    }
  }

  &__search {
    .foquz-search-field {
      height: 100%;
      min-height: 56px;
      input {
        border-radius: 0;
        border: none;
        height: 100%;
      }
    }
    &.full {
      flex: 1 1 auto;
      border-top: 1px solid #eceff1;
      margin-top: -1px;
    }
  }
  &__settings {
    border-top: 1px solid #eceff1;
    margin-top: -1px;
  }

  .mobile-and-tablet({
    border-radius: 0;
  });
}

.modal-page {
  .modal-page__body.f-card {
    border-radius: 0;
    padding-left: 0;
    padding-right: 0;

    .f-card {
      &__section {
        padding-left: 30px;
        padding-right: 30px;
      }
      &__full {
        margin-left: -30px;
        margin-right: -30px;
      }
      &__footer {
        padding-left: 30px;
        padding-right: 30px;
      }
    }

    .info-row {
      padding-left: 10px;
      padding-right: 10px;

      &__section {
        padding-left: 20px;
        padding-right: 20px;
      }
    }
  }
}
