

@import '../vars/colors.less';


.color-active {
  color: @f-color-blue-2;
}

.color-success {
  color: @f-color-success;
}

.color-service {
  color: @f-color-service;
}

.color-text {
  color: @f-color-text;
}

.f-color-primary {
  color: @f-color-primary;
  &.f-color-hover {
    &:hover {
      color: @f-color-primary--hover;
    }
  }
}

.f-color-service {
  color: @f-color-service;
}

.f-color-light {
  color: @f-color-light;
}


.f-color-text {
  color: @f-color-text;
}

.f-color-success {
  color: @f-color-success;
}

.f-color-danger {
  color: @f-color-danger;
}

.f-color-white {
  color: white;
}

.f-bg-body {
  background: #f5f6fa;
}

each(.colors(), .(@v, @k) {
  .f-color-@{k} {
    color: @v;
  }
  .f-bg-@{k} {
    background-color: @v;
  }
});

each(.colored-statuses(), .(@v, @k) {
  .f-color-status--@{k} {
    color: @v;
  }
});

each(.mailing-statuses(), .(@v, @k) {
  .f-color-mailing-@{k} {
    color: @v;
  }
});

each(.channels(), .(@v, @k) {
  .f-color-channel-@{k} {
    color: @v;
  }
});
