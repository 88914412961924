@f-xs-top: 767px;
@f-md-top: 991px;
@f-lg-top: 1199px;




.only-mobile(@content) {
  @media screen and (max-width: @f-xs-top) {
    @content();
  }
}

.mobile-and-tablet(@content) {
  @media screen and (max-width: @f-lg-top) {
    @content();
  }
}

.only-tablet(@content) {
  @media screen and (min-width: @f-xs-top + 1px) and (max-width: @f-lg-top) {
    @content();
  }
}

.tablet-and-desktop(@content) {
  @media screen and (min-width: @f-xs-top + 1px) {
    @content();
  }
}

.only-desktop(@content) {
  @media screen and (min-width: @f-lg-top + 1px) {
    @content();
  }
}
