.hide-on-mobile {
  @media screen and (max-width: 767px) {
    display: none !important;
  }
}

.show-on-desktop {
  .mobile-and-tablet({
    display: none!important;
  });
}
