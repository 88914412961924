@import "_mixins";

.btn {
  transition: background-color 0.3s ease, color 0.3s ease, border 0.3s ease;
  .border-radius(4px);
  font-family: "Roboto", sans-serif !important;
  font-size: 14px;
  font-weight: 500;
  height: 38px;
  padding: 8.5px 16.5px;
  border: none !important;

  &[disabled] {
    opacity: 0.5;
  }
}

.btn-default {
  background-color: #dadfe3;
  color: #73808d;

  &:focus {
    box-shadow: none;
  }

  &:hover {
    background-color: #c6cfd3;
  }

  &[disabled] {
    background-color: #dadfe3 !important;
    color: #73808d !important;
  }
}

.btn-danger {
  background-color: #ffdddd;
  color: #f96261;
  border-color: transparent;

  &:hover {
    background-color: #ffc1c1;
    color: #f96261;
    border-color: transparent;
  }

  &[disabled] {
    background-color: #ffdddd !important;
    color: #f96261 !important;
  }
}

.btn-success {
  background-color: #abefb7;
  color: #37a74a;
  border-color: transparent;

  &:hover {
    background-color: #83ee9d;
    color: #37a74a;
    border-color: transparent;
  }

  &:focus {
    box-shadow: none;
  }

  &:active {
    box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
  }

  &[disabled] {
    background-color: #abefb7 !important;
    color: #37a74a !important;
  }
}

.btn-active {
  background-color: #3f65f1;
  color: white;
}

.btn-lg {
  height: 48px;
}

.btn-link {
  padding: 0;

  text-decoration: none !important;
  color: #3f65f1;

  &:disabled {
    opacity: 0.5;
  }

  &-success {
    color: @f-color-success!important;
  }

  &-error {
    color: @f-color-danger!important;
  }
}

.btn-with-icon {
  padding-left: 40px;
  position: relative;

  &:before {
    content: "";
    display: inline-block;
    position: absolute;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    left: 15px;
    transition: all 600ms cubic-bezier(0.23, 1, 0.32, 1);
  }

  &.btn-save {
    &:before {
      top: 9px;
      width: 15px;
      height: 15px;
    }

    &.btn-success {
      &:before {
        .icon-save-question(#37a74a);
      }
    }

    &.btn-default {
      &:before {
        .icon-save-question(#73808d);
      }
    }
  }

  &.btn-add:before {
    .icon-add(#37a74a);
    top: 10px;
  }

  &.btn-apply:before {
    .icon-done(#37a74a);
    top: 13px;
  }

  &.btn-dismiss:before {
    .icon-dismiss(#F96261);
    top: calc((100% - 18px) / 2);
  }

  &.btn-times {
    &:before {
      .icon-plus5(#F96261);
      top: 11.5px;

      transform: rotate(45deg);
    }

    &.btn-danger {
      &:before {
        .icon-plus5(#F96261);
      }
    }
  }

  &.btn-delete {
    &:before {
      width: 17px;
      height: 17px;
      top: 9px;
    }

    &.btn-danger {
      &:before {
        .icon-delete(#F96261);
      }
    }

    &.btn-default {
      &:before {
        .icon-delete(#73808d);
      }
    }
  }

  &.btn-upload-disk:before {
    .icon-upload2(#73808d);
    width: 17px;
    height: 19px;
  }

  &.btn-upload-link:before {
    .icon-link3(#73808d);
    top: 9px;
    width: 21px;
    height: 19px;
  }

  &.btn-next {
    padding-left: 15px;
    padding-right: 45px;

    &:before {
      .icon-arrow-back(#73808d);
      transform: rotate(180deg);
      top: 10px;
      right: 15px;
      left: auto;
    }
  }

  &.btn-back {
    &:before {
      .icon-arrow-back(#73808d);
      top: 10px;
      left: 10px;
    }
  }

  &--simple {
    padding: 0 0 0 25px;

    background: none !important;
    color: #2e2f31 !important;
    font-weight: bold;
    box-shadow: none !important;

    &:before {
      left: 0;
    }

    &[disabled] {
      background: none !important;
      color: #2e2f31 !important;
    }

    &.btn-delete:before {
      top: 0.5px;
    }
  }
}

.btn-upload-disk {
  padding-left: 43px;
}

.btn-upload-link {
  padding-left: 47px;
}

.btn-circle {
  width: 32px;
  height: 32px;
  line-height: 32px;
  .border-radius(16px);
  padding: 0;
}

.btn-white {
  height: 38px;
  background-color: #fff;
  color: #000;
  font-size: 13px;
  .border-radius(4px);
  padding: 8px 15px;

  &:hover {
    background-color: #e7e7e7;
    //color: #73808d;
  }
}

.btn-icon {
  display: inline-flex;
  flex-grow: 0;
  flex-shrink: 0;
  width: 34px !important;
  height: 34px !important;
  align-items: center;
  justify-content: center;
  padding: 0;

  border-radius: 50%;
  border: 0;
  outline: none;

  &:before {
    content: "";
  }

  &:focus {
    box-shadow: none;
  }

  &.btn-default {
    background: #fff;

    &:hover {
      background: #c6cfd3;
    }
  }

  &--simple {
    width: unset !important;
    height: unset !important;
    background: none !important;
    padding: 6px;
  }
}

.button-add {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 38px;
  padding: 0 20px;
  position: relative;
  z-index: 1;

  border: 0;
  border-radius: 4px;
  background-color: #3f65f1;
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
  line-height: 1;
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
  transition: all 600ms cubic-bezier(0.23, 1, 0.32, 1);

  &:hover {
    background-color: #3553c1;
    color: #fff;
  }

  &[disabled] {
    opacity: 0.5;
  }

  &--with-dropdown {
    padding-right: 60px;

    &:before {
      .icon-arrow-bottom2(#fff);

      content: "";

      display: block;
      position: absolute;
      top: 50%;
      right: 20px;
      transform: translate(50%, -50%);
      z-index: 1;

      background-color: transparent;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }

    &:after {
      content: "";

      display: block;
      position: absolute;
      width: 40px;
      height: 100%;
      top: 0;
      right: 0;

      background: #3a5cdc;

      transition: all 600ms cubic-bezier(0.23, 1, 0.32, 1);
    }

    &:hover {
      &:after {
        background-color: #3553c1;
      }
    }
  }
}

.btn-survey-white {
  display: inline-flex;
  padding: 9px 22px;
  align-items: center;
  justify-content: center;

  background: #ffffff;
  border-radius: 100px;
  font-weight: bold;
  font-size: 15px;
  color: #2e2f31;
  box-shadow: none !important;

  &--small {
    padding: 7.5px 17px;

    font-size: 13px;
  }
}

.i-ajax-btn {
  background: #abefb7;
  border-radius: 4px;
  width: 60px;
  height: 48px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  box-shadow: none !important;

  &[disabled] {
    opacity: 0.6;
    cursor: default;
  }

  .i {
    display: inline-flex;
    .icon-check-arrow(#37a74a);
  }

  &_loading {
    .i {
      display: inline-flex;
      .icon-check-loading(#37a74a);
      animation: rotate 4s linear infinite;
    }
  }
}

.i-ajax-btn {
  background: #abefb7;
  border-radius: 4px;
  width: 60px;
  height: 48px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  box-shadow: none !important;

  &[disabled] {
    opacity: 0.6;
    cursor: default;
  }

  .i {
    display: inline-flex;
    .icon-check-arrow(#37a74a);
  }

  &_loading {
    .i {
      display: inline-flex;
      .icon-check-loading(#37a74a);
      animation: rotate 4s linear infinite;
    }
  }
}

.btn-icon-edit {
  &:before {
    .icon-pencil(@f-color-service);
    content: "";
  }
}

.btn-icon-copy {
  &:before {
    .icon-link3(@f-color-service);
    content: "";
  }
}

.btn-icon-add {
  &:before {
    .icon-plus2(#FFF);
    content: "";
  }
}

.btn-icon-remove {
  &:before {
    .icon-delete(@f-color-service);
    content: "";
  }
}

.btn-remove {
  width: 38px;
  height: 38px;
  padding: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &:before {
    .icon-add(@f-color-danger);
    display: inline-block;
    transform: rotate(45deg);
    content: "";
  }
}

.btn-view {
  padding: 0;
  &:before {
    content: "";
    display: inline-block;
  }
  &--desktop {
    &:before {
      .icon-desktop(@f-color-service);
    }
    &:hover {
      &:before {
        .icon-desktop(@f-color-blue-2);
      }
    }
    &:disabled,
    &.disabled {
      &,
      &:hover {
        &:before {
          .icon-desktop(@f-color-service);
        }
      }
    }
  }
  &--tablet {
    &:before {
      .icon-tablet(@f-color-service);
    }
    &:hover {
      &:before {
        .icon-tablet(@f-color-blue-2);
      }
    }
    &:disabled,
    &.disabled {
      &,
      &:hover {
        &:before {
          .icon-tablet(@f-color-service);
        }
      }
    }
  }
  &--mobile {
    &:before {
      .icon-mobile(@f-color-service);
    }
    &:hover {
      &:before {
        .icon-mobile(@f-color-blue-2);
      }
    }
    &:disabled,
    &.disabled {
      &,
      &:hover {
        &:before {
          .icon-mobile(@f-color-service);
        }
      }
    }
  }
}



@keyframes rotate {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

a {
  color: #3f65f1;
}
