

@import './_select.less';
@import './_scale.less';
@import './_input-group.less';
@import './_form-control.less';



input::-ms-clear {
  display: none;
}

.form-group {
  &--disabled {
    position: relative;
    opacity: 0.5;

    .form-control[disabled] {
      background: transparent;
    }

    &:after {
      /// content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 10;
    }
  }
}

.not-number {
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  & {
    -moz-appearance: textfield;
  }
}
