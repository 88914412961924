.b-landing__about {
  padding-top: 100px;
  position: relative;
  z-index: 3;
  overflow: hidden;
  font-size: 17px;
  line-height: 1.6;

  .body {
    max-width: 975px;
    margin-left: auto;
    margin-right: auto;
  }

  .title {
    font-weight: 900;
    font-size: 40px;
    line-height: 100%;
    color: #000000;
    margin-bottom: 34px;
  }

  .faq {
    line-height: 1.4;
    &-item {
      border: 2px solid #e2e8fd;
      padding: 30px;



      &:not(:first-child) {
        margin-top: -2px;
      }

      &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
      }

      &__title {
        font-size: 26px;
        font-weight: 900;
        line-height: 1.2;
        margin-right: 20px;
      }

      &__toggler {
        transform: rotate(-180deg);
        transition: transform 250ms ease-in-out;
        flex-shrink: 0;
      }

      &.open {
        padding-bottom: 35px;
        .faq-item__toggler {
          transform: rotate(0deg);
        }
        .faq-item__content {
          padding-top: 4px;
        }
      }

      &__content {
        display: none;

        p {
          margin: 20px 0;
        }

        ul {
          margin: 15px 0 20px;
          list-style: none;
        }

        p, ul, li {
          &:last-child {
            margin-bottom: 0;
          }
        }

        li {
          margin: 15px 0;
          position: relative;

          padding-left: 25px;

          &:before {
            content: '';
            position: absolute;
            display: block;
            width: 8px;
            height: 8px;
            transform: rotate(45deg);
            background: #b0c0ff;
            top: calc(1em - 9px);
            left: 0px;
          }
        }

        h3 {
          font-size: 18px;
          font-weight: 900;
          padding: 0;
          margin: 20px 0 8px;
        }
      }
    }
    &-actions {
      display: flex;
      margin-top: 28px;
      margin-bottom: 5px;

      .btn {
        margin-right: 20px;
        width: 250px;
        height: 50px;
        font-size: 17px;
        font-weight: 500;
      }
    }
    &-features {
      display: flex;
      flex-wrap: wrap;
      margin-left: -20px;
      margin-right:  -20px;
      margin-bottom: -16px;

      .faq-feature {
        flex-basis: 50%;
        padding-left: 20px;
        padding-right: 20px;
        margin-bottom: 16px;

        &__title {
          font-size: 18px;
          font-weight: 900;
          margin-bottom: 8px;
          margin-top: 0;
        }

        &__text {
          margin: 0;
        }
      }
    }
  }

  @media (max-width: @breakpointXl) {
    .faq {
      &-actions {
        margin-top: 24px;
      }
      &-features {
        .faq-feature {
          flex-basis: 100%;
        }
      }
    }
  }

  @media (max-width: 767px) {
    font-size: 13px;

    .title {
      font-size: 25px;
      margin-bottom: 25px;
    }

    .faq {
      margin-left: -22px;
      margin-right: -22px;

      &-item {
        padding: 16px 22px;


        &__title {
          font-size: 15px;
        }
        &__content {
          font-size: 13px;

          p, ul {
            margin-top: 15px;
            margin-bottom: 15px;
          }

          li {
            margin-top: 10px;
            margin-bottom: 10px;
          }
        }

        &.open {
          padding-bottom: 24px;
        }
      }

      &-actions {
        margin-top: 16px;
        margin-bottom: 6px;
        display: block;

        .btn {
          width: 100%;
          margin-right: 0;
          height: 40px;
          font-size: 15px;

          &:first-child {
            margin-bottom: 15px;
          }
        }
      }

      &-features {
        .faq-feature {
          &__title {
            font-size: 15px;
            margin-bottom: 5px;
          }
        }
      }
    }
  }
}
