.b-landing__modules {
  margin-bottom: 135px;
}

.modules {
  display: flex;
  font-size: 15px;
  margin-left: -10px;
  margin-right: -10px;

  .module {
    width: calc((100% - 60px) / 3);
    overflow: hidden;

    height: 480px;
    margin: 0 10px;
    position: relative;

    transition: width 400ms ease-in-out;
    cursor: pointer;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border: 2px solid #e2e8fd;
    }

    .sm {
      display: none !important;
    }

    &-content,
    &-preview {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      transition: opacity 250ms ease-in-out, visibility 250ms ease-in-out;
    }

    &-content {
      opacity: 0;
      visibility: hidden;
      cursor: pointer;
      padding: 40px 56px 45px;

      &__name {
        font-size: 35px;
        font-weight: 900;
        margin-bottom: 12px;
      }

      &__description {
        margin-bottom: 27px;
      }
    }

    .module__items {
      display: flex;
      flex-wrap: wrap;
    }

    &__footer {
      padding-top: 5px;
    }

    .module-item {
      margin-bottom: 20px;
      padding-right: 10px;

      &__icon {
        margin-bottom: 16px;
      }
    }

    &-preview {
      padding: 35px 45px;
      height: 100%;
      display: flex;
      flex-direction: column;
      cursor: pointer;

      &__name {
        font-size: 26px;
        font-weight: 900;
        margin-bottom: 16px;

        svg {
          flex-shrink: 0;
          margin-left: 10px;
        }
      }

      &__img {
        width: 195px;
        height: 195px;
        margin: auto;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          object-position: center;
        }
      }
    }

    &--inactive {
      width: 200px;
      flex-shrink: 0;

      .module-preview {
        padding: 35px 25px;

        &__name {
          font-size: 18px;
          max-width: 136px;
        }

        &__img {
          img {
            position: relative;
            left: 10px;
          }
        }
      }

      &[data-module='constructor'] {
        .module-preview__img {
          img {
            position: relative;
            left: 9px;
          }
        }
      }
    }

    &--active {
      width: calc(100% - 60px - 400px);
      .module-preview {
        opacity: 0;
        visibility: hidden;
      }
      .module-content {
        opacity: 1;
        visibility: visible;
      }
    }



    &[data-module='constructor'] {
      .module-item {
        width: 33.33%;
        max-width: 180px;
      }

      .module-content__description {
        max-width: 620px;
      }
    }

    &[data-module='mailing'] {
      .module-item {
        width: 25%;
        max-width: 140px;

        &__text {
          max-width: 116px;
        }
      }
    }

    &[data-module='control'] {
      .module-item {
        width: 33.33%;
        max-width: 200px;

        &__text {
          max-width: 145px;
        }
      }
    }
  }
}

@media (max-width: @breakpointXl) {
  .b-landing__modules {
    margin-bottom: 45px;
  }

  .modules {
    display: block;
    margin-left: 0;
    margin-right: 0;

    .module {
      width: 100%;
      height: auto;
      margin: 10px 0;

      &__name {
        max-width: none!important;
      }

      .sm {
        display: block !important;
      }

      .md {
        display: none !important;
      }

      &-preview,
      &-content {
        position: relative;
      }

      &-preview {
        height: auto;
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;

        align-items: center;
        padding: 20px 30px;

        &__img {
          width: 80px;
          height: 80px;
          margin-right: 42px;
          margin-left: 0;

          img {
            left: 0!important;
          }
        }

        &__name {
          font-size: 26px;
          margin-bottom: 0;
        }
      }

      &-content {
        display: none;
        margin-top: 12px;
        padding: 0 20px 45px 60px;
        &__name {
          display: none;
        }
      }

      .module__items {
        max-width: 585px;
      }

      &[data-module='constructor'] {
      }

      &[data-module='mailing'] {
        .module-item {
          &__text {
            max-width: none;
          }
        }
      }

      &[data-module='control'] {
        .module-item {
          &__text {
            max-width: none;
          }
        }
      }

      &--active {
        width: 100%;

        .module-preview {
          opacity: 1;
          visibility: visible;
          padding: 20px 30px;

          svg {
            display: none;
          }
        }
      }

      &--inactive {
        width: 100%;

        .module-preview {
          padding: 20px 30px;

          &__name {
            font-size: 26px;
            max-width: none;
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .modules {
    .module {
      &-preview {
        padding-left: 20px;
        padding-right: 20px;
        &__img {
          display: none;
        }
        &__name {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 18px;
          margin-bottom: 0px;
          width: 100%;

          svg {
            margin-left: 12px;
          }
        }
      }
      &__footer {
        .btn {
          width: 100%;
        }
      }
      &-content {
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 36px;

        &__description {
          font-size: 13px;
        }
      }

      &--active,
      &--inactive {
        .module-preview {
          padding-left: 20px;
          padding-right: 20px;

          &__name {
            font-size: 18px;
            margin-bottom: 12px;
            max-width: none;
          }
        }
      }

      &--inactive {
        .module-preview {
          &__name {
            margin-bottom: 0;
          }
        }
      }

      &--active {
        .module-preview {
          padding-bottom: 0;
          &__name {
            margin-bottom: 0px;
          }
        }
      }

      &[data-module] {
        .module-item {
          display: flex;
          align-items: center;
          width: 100%;
          max-width: 100%;
          padding-right: 0px;

          &__icon {
            margin-bottom: 0;
            margin-right: 14px;
            width: 25px;
            flex-shrink: 0;
            text-align: center;
          }

          &__text {
            font-size: 13px;
          }
        }
      }

      &[data-module='mailing'] {
        .module-item {
          flex-basis: 40%;
          &:nth-child(odd) {
            flex-basis: 58%;
            padding-right: 22px;
            max-width: 220px;
          }
        }
      }

    }
  }
}
