.f-tabs {
  display: flex;
  flex-direction: column;

  &__nav {
    display: flex;
    flex-wrap: nowrap;
    height: 48px;
    background: #f5f6fa;

    &-item {
      display: flex;
      flex-grow: 1;
      flex-basis: 100%;
      align-items: center;
      justify-content: center;

      background: rgba(190, 198, 202, 0.488168);
      border: none;
      border-radius: 4px 4px 0 0;
      font-size: 14.6667px;
      font-weight: 500;
      color: #73808D;
      cursor: pointer;

      &:not(:first-child) {
        margin-left: 2px;
      }

      &.active {
        background: #FFF;
        color: #333;
        font-weight: 700;
      }

      &.disabled {
        opacity: 0.6;
      }
    }
  }

  &__content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  .f-tab {
    display: none;
    flex-grow: 1;
    flex-direction: column;
    padding-top: 12px;

    &.active {
      display: flex;
    }
  }
}

.f-card {
  .f-tabs {
    flex-grow: 1;
  }
}
