.stats-item {
  &__value {
    font-weight: bold;
    font-size: 19px;
    line-height: 22px;
    margin-bottom: 4px;
  }
  &__text {
    line-height: 22px;
    margin-bottom: 4px;
  }
  &__label {
    font-weight: 500;
    font-size: 12px;
    line-height: 1;
    color: @f-color-service;
  }
  &__icon {
    width: 20px;
    height: 20px;
    font-size: 0;
    margin-bottom: 4px;

    .f-icon, svg {
      width: 20px;
      height: 20px;
    }
  }

  &--primary {
    .stats-item__value {
      color: @f-color-primary;
    }
  }

  &--goals {
    .stats-item__value {
      color: @f-color-gold;
    }
  }
}
