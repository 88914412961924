//*********************************************
//		Assets
//*********************************************
@import "parts/reset";

@import "parts/_mixins";
@import "parts/_buttons";
@import "parts/_form";
@import "parts/_modal";
@import "parts/_hat-radio-group";

//*********************************************
//		Blocks
//*********************************************

@import "pages/landing/landing";

.b-landing__logo {
  background-image: url("/img/logo-ru.svg");
}
