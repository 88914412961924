@import '_mixins';

textarea {
  resize: vertical !important;
}

input,
textarea,
select {
  font-family: 'Roboto', sans-serif !important;
}

.form-group {
  margin-bottom: 25px;

  &__show-password {
    display: none;
    & + label {
      display: inline-flex;
      position: absolute;
      bottom: 41px;
      right: 30px;
      .icon-show-password(#73808d);
      padding: 0 !important;
      width: 18px;
      height: 18px;
      &:before,
      &:after {
        display: none !important;
      }
    }
    &:checked + label {
      .icon-hide-password(#73808d);
      width: 18px;
      height: 18px;
    }
  }

  &.maxchars {
    position: relative;

    .maxchars__cur {
      color: #cfd8dc;
      font-size: 11px;
      position: absolute;
    }

    .maxchars__input {
      & + .maxchars__cur {
        bottom: 18px;
        right: 10px;
      }

      &:focus + .maxchars__cur {
        color: #73808d;
      }
    }

    .maxchars__textarea {
      & + .maxchars__cur {
        bottom: 10px;
        right: 10px;
      }

      &:focus + .maxchars__cur {
        color: #73808d;
      }
    }

    .maxchars__textarea.is-invalid + .maxchars__cur {
      bottom: 25px;
    }
  }

  &--required {
    label:after {
      content: '*';
      color: #ea1d27;
    }
  }
}

.form-group-header {
  display: flex;
  margin-bottom: 10px;

  .form-label {
    margin-bottom: 0;
  }

  .form-group-note {
    align-self: flex-end;

    font-size: 12px;
    line-height: 120%;
    color: #73808d;
  }
}

.chars-counter {
  position: relative;

  &--type_textarea {
    .chars-counter__value {
      bottom: 10px;
      right: 10px;
    }
  }

  &--type_input {
    .chars-counter__value {
      bottom: 18px;
      right: 10px;
    }
  }

  &__value {
    position: absolute;

    font-size: 11px;
    color: #cfd8dc;
  }

  input:focus + &__value,
  textarea:focus + &__value {
    color: #73808d;
  }

  .is-valid + .chars-counter__value {
    display: none;
  }
}

.form-label {
  font-size: 16px;
  font-weight: bold;
  color: #2e2f31;
  margin-bottom: 10px;

  &_not-required {
    font-size: 12px;
    font-weight: normal;
    color: #73808d;
  }

  &__secondary {
    font-weight: normal;
  }

  &__note {
    font-size: 12px;
    font-weight: normal;
    color: #73808d;
    &_link {
      font-size: 12px;
      font-weight: normal;
    }
  }
}

.form-control {
  .border-radius(4px);
  border-color: #cfd8dc;
  line-height: 1.36;
  font-size: 16px;
  padding: 15px 15px;
  color: #2e2f31;
  font-weight: 400;
  height: 48px;

  &::placeholder {
    color: #73839b;
    font-weight: normal;
    font-size: 16px;
  }

  &:focus {
    color: #2e2f31;
    border: 1px solid #3f65f1;
    box-shadow: 0 0 5px rgba(63, 101, 241, 0.5);
  }

  &[disabled] {
    background-color: #f2f5f6 !important;
    color: #73808d;
  }

  &[readonly] {
    background-color: #fff !important;
  }

  

  &.is-valid:not(.is-invalid),
  .is-valid:not(.is-invalid) & {
    .icon-done(#37a74a);

    width: 100%;
    height: 48px;

    background-size: auto auto !important;
    /*background-position: 15px right 15px !important;*/
    background-position: center right calc(.375em + .1875rem);
    border: 1px solid #37a74a;

    textarea& {
      height: auto;
    }
  }

  &.is-invalid,
  .is-invalid & {
    background: none !important;
    border: 1px solid #f96261 !important;
    box-shadow: 0 0 5px rgba(249, 98, 97, 0.5) !important;
  }

  &.readonly {
    background-color: #f2f5f6 !important;
    color: #73808d;
  }
}

.invalid-feedback {
  margin-top: 6px;

  font-size: 12px;
  line-height: 120%;
  color: #ea1d27;
}

.form-error {
  display: inline-block;
  margin-top: 6px;

  font-size: 12px;
  line-height: 15.6px;
  color: #ea1d27;
}

.form-hint {
  display: inline-block;
  margin-top: 6px;

  font-size: 11px;
  line-height: 13px;
  color: #73808d;
}

.form-delim {
  background: rgba(207, 216, 220, 0.35);
  margin-left: -20px;
  margin-right: -20px;
}

.form-btns {
  .flex();

  .btn {
    margin-right: 18px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.btn-group-select {
  display: block;
  width: 100%;
  position: relative;

  &:before {
    position: absolute;
    top: 0;
    right: 15px;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    font-family: 'Font Awesome 5 Pro';
    font-size: 16px;
    font-weight: 900;
    content: '\f078';
    color: #73808d;
    line-height: 48px;
    vertical-align: center;
    transition: all 600ms cubic-bezier(0.23, 1, 0.32, 1);
  }

  &.arrow-top:before {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  .form-control[readonly] {
    //background-color: #fff;
    padding-right: 35px;
    z-index: 2;
    position: relative;
    background: transparent;
  }

  .dropdown-menu {
    width: 100%;
    padding: 8px 0;
    .border-top-radius(0px);
    border-top-color: #fff;
    position: relative;

    border: none;
    top: 11px !important;
    background: #ffffff;
    border-radius: 9px;
    box-shadow: 0 5px 20px rgba(115, 128, 141, 0.4);

    &:before {
      content: '';
      .popover-arrow(#FFF);

      position: absolute;
      top: -5px;
      right: 18px;
    }
  }

  .dropdown-item {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    color: #3f65f1 !important;
    padding: 0 23px;
    display: flex;
    align-items: center;
    font-size: 14px;
    height: 30px;

    &:not(:first-child) {
      margin-top: 4px;
    }

    &.active {
      background-color: transparent;
      color: #2e2f31 !important;
    }

    &:hover {
      background: #f1f5f6;
    }

    &:last-child {
      border-bottom: 0;
    }
  }
}

input[type='checkbox'] {
  position: absolute;
  display: none;

  & + label {
    padding-left: 14px;
    cursor: pointer;
    color: #73808d;
    transition: color 0.185s ease-in-out;
  }

  & + label:before {
    content: '';
    display: inline-block;
    position: absolute;
    top: -3.6px;
    left: 0;
    width: 24px;
    height: 24px;

    border: solid 1px #cfd8dc;
    background: #ffffff;
    box-sizing: border-box;
    border-radius: 6px;
  }

  & + label:after {
    .icon-checkbox(#3f65f1);

    content: '';

    font-weight: 900;
    font-size: 8px;
    display: inline-block;
    opacity: 0;
    position: absolute;
    top: 4.4px;
    left: 7.5px;
  }

  &:checked + label:after {
    opacity: 1;
  }

  &:checked + label {
    color: #2e2f31;
  }

  &:disabled + label {
    opacity: 0.4;
  }
}



.inner-input-group {
  position: relative;

  .form-control {
    border-radius: 4px !important;
  }

  &__suffix {
    position: absolute;
    z-index: 3;
  }
}

.password-inner-input-group {
  .form-control {
    padding-right: calc((10px * 2) + (6px * 2) + 18px);
  }

  &__visibility-toggle-icon-button-suffix {
    transition: none;
  }

  &__show-password-icon-button-suffix {
    &:before {
      .icon-visible(#73808d);
    }

    top: calc(48px / 2 - (13px + 6px * 2) / 2);
    right: 10px;
  }

  &__hide-password-icon-button-suffix {
    &:before {
      .icon-hidden(#73808d);
    }

    top: calc(48px / 2 - (21px + 6px * 2) / 2);
    right: 9px;
  }
}

.select2 {
  &:not(.foq-table__group-actions-selector) {
    width: unset !important;
  }

  display: inline;

  &-wrapper {
    &.is-invalid {
      .select2 .form-control {
        border-color: #f96261;
        box-shadow: 0 0 5px rgba(249, 98, 97, 0.5) !important;
      }
    }

    &.is-valid {
      .select2 .form-control {
        border-color: #37a74a;
        background-image: none;
      }
    }

    position: relative;

    .form-check {
      position: absolute;
      right: 20px;
      top: 15px;
      z-index: 1000;
    }

    &--with-flag {
      .select2 .selection .select2-selection {
        padding-right: 140px;
      }
    }
  }

  &.select2-container--form-control {
    display: block;

    &.select2-container--disabled {
      .select2-selection {
        background-color: #f2f5f6 !important;
      }

      .select2-selection__rendered {
        color: #73808d !important;
      }
    }
  }

  &.select2-container--focus:not(.select2-container--disabled) {
    .select2-selection.form-control {
      border: 1px solid #3f65f1;
      box-shadow: 0 0 5px rgba(63, 101, 241, 0.5);
    }
  }

  &.select2-container--focus {
    .select2-selection.form-control {
      box-shadow: none;
    }
  }

  &.select2-container--open {
    .select2-selection__arrow {
      b {
        transform: rotate(180deg);
      }
    }
  }

  .selection {
    display: flex;
    background: white;
  }

  .select2-selection {
    display: inline-flex;
    align-items: center;

    border: none;

    &.form-control {
      height: 48px;
      padding-left: 0;
      padding-right: 0;

      border: 1px solid #cfd8dc;
      border-radius: 4px !important;

      .select2-selection__arrow {
        margin-right: 19px;

        b {
          .icon-select-arrow(#73808d) !important;
        }
      }

      .select2-selection__rendered {
        padding-left: 18px !important;
      }

      .select2-selection__placeholder {
        font-size: 16px;
      }

      &.is-invalid {
        background: none !important;
        border: 1px solid #f96261 !important;
        box-shadow: 0 0 5px rgba(249, 98, 97, 0.5) !important;
      }

      &.is-valid {
        border: 1px solid #37a74a;
        background: none;
      }
    }

    &--multiple.select2-selection {
      min-height: 48px;
      height: unset !important;
      align-items: unset;
      padding-top: 0;
      padding-bottom: 0;

      .select2-selection__rendered {
        display: block !important;
        padding: 0px 9px;
      }

      .select2-selection__choice {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        max-width: 100%;
        white-space: normal!important;
        padding: 6px 6px 6px 16px;
        background: #eceff1;
        border-radius: 20px;
        border: none;
        font-size: 14px;
        color: #2e2f31;
        margin-bottom: 7px;
      }

      .select2-selection__choice__remove {
        width: 20px;
        height: 20px;
        flex-shrink: 0;
        margin-left: 12px;
        align-items: center;
        justify-content: center;
        position: relative;

        border-radius: 50%;
        background: #fff;

        text-indent: 100%;
        white-space: nowrap;
        overflow: hidden;

        &:before {
          .icon-cross(#F96261) !important;

          content: '';
          display: inline-block;
          position: absolute;
          top: 6px;
          left: 6px;
        }
      }

      .select2-search {
        margin-top: 5px;
        margin-left: 8px;

        .select2-search__field::placeholder {
          color: #73839b;
        }
      }
    }

    &__rendered {
      flex-grow: 1;
      padding-left: 0 !important;
      padding-right: 18px !important;

      font-weight: 400;
      line-height: 1.36 !important;
      font-size: 16px;
      color: #2e2f31 !important;
    }

    &__placeholder {
      font-weight: normal;
      font-size: 14px;
      color: #73839b !important;
    }

    &__arrow {
      display: block;
      top: unset !important;
      right: unset !important;
      width: unset !important;
      height: unset !important;
      position: relative !important;

      b {
        .icon-select-arrow(#323232) !important;

        display: block;
        position: relative !important;
        top: 0 !important;
        left: 0 !important;
        margin: 0 !important;

        border: none !important;

        transition: transform 0.3s ease;
      }
    }

    &__clear {
      .icon-plus3(#F96261) !important;

      width: 14px !important;
      height: 14px !important;
      order: 1;
      margin-left: 8px;
      margin-right: -7px;
      margin-top: 2px;

      flex-shrink: 0;

      transform: rotate(45deg);
      text-indent: 100%;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  &-search {
    &--inline {
      width: 100%;
    }

    .select2-search__field {
      min-width: 100%;
    }

    .select2-selection__choice + & {
      width: auto;
    }
  }
}

.select2-container .select2-dropdown {
  min-width: 46px;
  padding: 8px 0;
  position: relative;

  border: none;
  background: #ffffff;
  border-radius: 9px;
  box-shadow: 0 5px 20px rgba(115, 128, 141, 0.4);

  &.tags-dropdown {
    max-width: 300px;
  }

  &:before {
    content: '';
    .popover-arrow(#FFF);

    position: absolute;
  }

  &--below {
    top: 11px !important;

    &:before {
      top: -5.4px;
      right: 15.7px;
    }
  }

  &--above {
    bottom: 11px !important;

    &:before {
      bottom: -5.4px;
      right: 15.7px;
      transform: rotate(180deg);
    }
  }

  .select2-search {
    padding: 4px 20px 14px 20px;

    &__field {
      padding: 9px;
      min-width: 100%;

      border: 1px solid #cfd8dc;
      border-radius: 4px !important;
    }
  }

  .select2-results {
    padding: 10px 0 10px;
    .scroll-element.scroll-y {
      right: 20px;
      .scroll-bar {
        min-height: 15px;
      }
    }

  }

  .select2-results__option {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;

    background: transparent;

    transition: background-color 0.3s ease;

    &[role='option'] {
      display: flex;
      align-items: center;
      font-size: 14px;
      color: #3f65f1 !important;
      min-height: 30px;
      padding: 4px 38px 4px 23px;
    }

    &[role='alert'] {
      padding: 4px 38px 4px 23px;
    }

    &[role='group'] {

      font-size: 14px;
      color: #73839b;
      font-weight: 500;

      .select2-results__group {
        padding: 0 38px 0 23px;
        min-height: 30px;
        height: auto;
        display: flex;
        align-items: center;
      }

      .select2-results__option[role='option'] {
        padding-left: 33px;
      }
    }

    &:not(:first-child) {
      margin-top: 4px;
    }

    &--highlighted {
      background: #f1f5f6;
    }

    &[aria-selected='true'] {
      color: #2e2f31 !important;
    }

    &[aria-disabled='true'] {
      color: #73808d !important;
    }

    &:last-child {
      border-bottom: 0;
    }
  }

  .dish-option,
  .item-option {
    margin-left: 14px;
    color: #2e2f31;
    padding-left: 12px;
  }

  .dish-category-option,
  .category-option {
    color: #37a74a;
    font-weight: bold;
    display: flex;
  }

  .select2-results__option[aria-selected="true"]  {
    .dish-category-option, .category-option {
      color: #abefb7 !important;
    }
  }

  .select2-results__option[aria-disabled='true'] & {
    .dish-option, .item-option {
      color: #73808d;
    }

  }
}

.select2 {
  .dish-category-value, .category-value {
    color: #37a74a;
    font-weight: 500;
  }
  &-results__options > li > span {
    word-break: break-word;
  }
}

.select-font {
  width: 152px;
  margin-right: 18px;
}

.button-font {
  padding-left: 12px;
  padding-right: 12px;
  color: #ccd3d9;
  cursor: pointer;
  &.active {
    color: #3a5cdc;
  }
}

.select2-container--limit {
  .select2-dropdown {
    min-width: 46px!important;
    max-width: 300px;
  }
}

.ui-slider {
  height: 6px;

  background: #e8edee;
  border-radius: 7px;
  border: none !important;
  cursor: pointer;

  .ui-slider-range {
    background: #9fb2f8;
    border-radius: 7px;
  }

  .ui-slider-handle {
    background: #3f65f1;
    border: none;
    border-radius: 50%;

    cursor: grab;

    &.ui-state-active {
      cursor: grabbing;
    }
  }
}

.form-control {
  &.ui-slider {
    margin-top: 21px;
    margin-bottom: 21px;
    padding: 0;
  }
}

.slider-input-group {
  .form-control {
    border-radius: 7px !important;
  }

  .input-group-text {
    padding-left: 14px;
    padding-right: 0;

    border: none;
    background: none;
    font-weight: bold;
    font-size: 16px;
    color: #2e2f31;
  }
}





.tinymce-wrapper {
  &.is-invalid {
    .tox-tinymce {
      border-color: #f96261;
      box-shadow: 0 0 5px rgba(249, 98, 97, 0.5) !important;
    }
  }

  &.is-valid {
    .tox-tinymce {
      border-color: #37a74a;
    }
  }
}

.cke-wrapper {
  &.is-invalid {
    .cke {
      border-color: #f96261 !important;
      box-shadow: 0 0 5px rgba(249, 98, 97, 0.5) !important;
    }
  }

  &.is-valid {
    .cke {
      border-color: #37a74a !important;
    }
  }
}

.editor {
  &__variables {
    display: flex;
    flex-wrap: wrap;
    margin-top: 5px;
    margin-left: -5px;
  }

  &-variable {
    border: none;
    box-shadow: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    padding-right: 15px;
    padding-left: 15px;
    margin-top: 5px;
    margin-left: 5px;
    background: #eceff1;
    border-radius: 60px;
    font-size: 13px;
    color: #2e2f31;
    cursor: pointer;
    transition: background-color 0.2s ease;

    &:hover {
      background-color: #c6cfd3;
    }
  }
}

.search-form {
  position: relative;

  .icon-search {
    position: absolute;
    left: 15px;
    top: 16px;
    margin-right: 11px;
  }

  .form-control {
    padding-left: 46px;
  }

  &__clear {
    position: absolute;
    right: 13px;
    top: calc(50% - (17px / 2));
    padding: 0;

    border-radius: 0;
    box-shadow: none !important;

    transition: none;
  }
}

.tox {
  .tox-dialog {
    max-width: 550px!important;
  }
}


.ghost-label {
  padding-top: 29px;
}

.ghost-label-2 {
  .fc-label {
    opacity: 0;
  }
  .fc-check {
    min-height: 48px;
  }
}
