@import '_mixins';
.modal {
  .modal-dialog {
    max-width: 100%!important;
    width: 333px;

    &-md {
      width: 460px;
    }

    &-lg {
      width: 700px;
      max-width: none!important;
    }

    .modal-content {
      border: none;
      border-radius: 9px;
      box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.18);

      .modal-header {
        justify-content: flex-start;
        padding: 38px 30px 11px 30px;
        position: relative;

        border-bottom: 0;

        &--no-title {
          height: 25px;
          padding: 0;
        }
      }

      .close {
        position: absolute;
        top: 15px;
        right: 12px;
        padding: 0;
        margin: 0;
        float: none;

        opacity: 1;

        span {
          display: none;
        }

        &:before {
          .icon-cross2(#73808d);

          content: '';

          display: inline-block;
        }
      }

      .modal-body {
        padding: 11px 30px 32px 30px;
      }

      .modal-footer {
        min-height: 75px;

        border-top: none;
        background: #fbfbfb;
        padding: 20px 30px;
        border-bottom-left-radius: 9px;
        border-bottom-right-radius: 9px;
      }

      .modal-title {
        font-weight: 900;
        font-size: 19px;
        line-height: 22.8px;
        color: #2e2f31;
        padding-bottom: 0;

        .form-error {
          font-weight: normal;
        }
      }

      .modal-text {
        font-size: 14px;
        line-height: 1.36;
        text-align: center;
        color: #7f7f7f;
        margin-top: 10px;
      }

      // DEPRECATED
      .modal-btns {
        display: flex;
        justify-content: flex-end;

        .btn {
          margin: 0;

          &:not(:first-child) {
            margin-left: 13px;
          }
        }
      }

      .modal-actions {
        display: flex;
        justify-content: flex-end;

        & > * {
          &:not(:first-child) {
            margin-left: 26px;
          }
        }
      }
    }
  }

  .modal-dialog-400 {
    width: 400px;
  }

  .modal-dialog-465 {
    width: 465px;
  }

  .modal-wrapper {
    &--right {
      margin-left: 60px;
      width: calc(100% - 60px);
      position: absolute;
      height: 100%;
      right: 0;
      max-width: 1500px;

      .modal-actions {
        position: absolute;
        transform: translate(calc(-100% + -16px), 11px);
        z-index: 2;
        display: flex;
      }

      .modal-close {
        width: 33px;
        height: 33px;
        flex-shrink: 0;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50px;
        box-shadow: none !important;
        border: 2px solid #73808d !important;
        .icon {
          width: 15px;
          height: 15px;
        }
      }
    }
    &--sm {
      max-width: 1000px;
    }
    &--lg {
      max-width: calc(100vw - 200px);

      @media screen and (min-width: 1500px) {
        max-width: 1300px;
      }
    }



    &--offset {
      right: 28px;
    }
  }

  .modal-page {
    padding-right: 0;
    background: #fff;
    height: 100%;
    width: 100%;

    &__status-line {
      height: 4px;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      background-color: currentColor;
    }

    &__header {
      padding: 11px 30px 0px 30px;
    }

    &__header-block {
      height: 80px;
      display: flex;
      align-items: center;
      padding: 10px 30px;
    }

    .modal-title {
      font-size: 22px;
      font-weight: 900;

      .form-error {
        font-weight: normal;
      }

      .breadcrumbs {
        .breadcrumbs__item {
          font-weight: 900;
          font-size: 22px;
          color: #73808D !important;

          &:last-child {
            color: #2E2F31 !important;
          }
        }
      }
    }

    &__content {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      box-shadow: 0 5px 20px rgba(115, 128, 141, 0.4);
      position: relative;
    }

    &__body {
      flex-grow: 1;
      padding: 0px 30px;
      overflow: hidden;
    }

    &__full {
      margin-left: -30px;
      margin-right: -30px;
    }

    &__actions {
      background: #fbfbfb;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 20px;
    }

    &__footer {
      position: relative;
      background: #fbfbfb;
      padding-left: 30px;
      padding-right: 30px;
      padding-top: 20px;
      padding-bottom: 20px;
      min-height: 80px;
      z-index: 2;

      &--gradient {
        &:before {
          content: '';
          position: absolute;
          top: -30px;
          height: 30px;
          left: 0;
          right: 0;
          background: linear-gradient(
            180deg,
            rgba(255, 255, 255, 0) 0%,
            #ffffff 100%
          );
        }
      }
    }
  }
}



.modal-backdrop.show {
  background: rgba(0, 0, 0, 0.65);
  opacity: 1;
}

.modal-with-base-backdrop {
  background: rgba(0, 0, 0, 0.65);
  opacity: 1;
}

.modal--light {
  background: rgba(255, 255, 255, 0.9);
}


@media screen and (max-width: 767px) {
  .modal {
    .modal-dialog {
      margin: auto;
      .modal-content {
        border-radius: 0;
      }
    }
  }

}
