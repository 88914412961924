.f-scroll {
  display: flex;
  flex-direction: column;

  .f-scroll__viewport {
    flex-grow: 1;
    overflow-y: hidden;
  }

  .f-scroll__wrapper {
    height: 100%;
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
  }

  .f-scroll__content {
    flex-grow: 1;
  }

  .scrollable-wrapper__curtain {
    z-index: 2;
  }
}
