.form-control {
  padding-left: 15px;
  padding-right: 15px;

  &.is-valid, &.is-invalid {
    &.form-control--simple-validation {
      background-image: none;
      padding-right: 15px;
    }
  }
}

.form-control-wrapper {
  min-height: 48px;
  display: flex;
  align-items: center;
}

input.form-control {
  padding-top: 5px;
  padding-bottom: 5px;
}
