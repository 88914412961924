.b-landing {
  &__container {
    width: 100%;
    max-width: 1240px;
    margin: 0 auto;
    padding: 0 30px;
  }

  &__grow {
    flex-grow: 1;
  }

  @media screen and (max-width: 767px) {
    &__container {
      padding: 0 20px;
    }
  }
}


.modal .buy-rate-modal-dialog {
  width: 530px;

  @media screen and (max-width: 480px) {
    .hat-radio-group {
      flex-direction: column;
      height: auto;

      .hat-radio-group__radio:not(:first-child) {
        border-left: 0;
        border-top: 1px solid #c4c4c4;
      }
    }
  }
}
