
.b-landing {
  &-footer {
    margin-top: 100px;
    padding: 30px 0px 0 !important;
    border-top: 2px solid #e2e8fd;
    font-size: 13px;

    @media screen and (max-width: 767px) {
      margin-top: 60px;
    }

    &__section {
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 25px;
    }

    &__container {
      width: 100%;
      max-width: 1180px;

      margin: 0 auto;
    }

    &__row {
      display: flex;
      justify-content: space-between;

      @media screen and (max-width: 767px) {
        flex-direction: column;

        >*:not(:last-child) {
          margin-bottom: 30px;
        }
      }
    }

    &__column {
      min-width: 265px;

      @media screen and (max-width: 1080px) {
        &--logo {
          min-width: unset;
        }
        &--contact {
          min-width: 154px;
        }
        &--menu {
          min-width: 94px;
        }
        &--links {
          min-width: 215px;
        }
      }
    }

    &__links {
      display: flex;
      flex-direction: column;
      margin-bottom: 26px;
    }

    &__logo-wrapper {
      display: flex;
      align-items: flex-end;
      margin-bottom: 15px;

      @media screen and (max-width: 1080px) {
        flex-direction: column;
        align-items: flex-start;
      }

      @media screen and (max-width: 767px) {
        flex-direction: row;
        align-items: flex-end;
      }
    }

    &__logo {
      margin-right: 20px;

      a {
        display: block;
        text-decoration: none;
        width: 94px;
        height: 21px;
        img {
          max-width: 100%;
          height: auto;
          display: block;
        }
      }
    }

    .registry-label {
      display: block;
      width: fit-content;

      .h1-label {
        font-size: 10px;
        line-height: 10px;
        padding: 10px 15px;

        :first-child {
          margin-bottom: 5px;
        }
      
        :last-child {
          font-size: 13px;
          line-height: 13px;
        }
      }
    }

    &__nav {
      list-style: none !important;
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 20px;

      @media screen and (max-width: 1080px) {
        flex-direction: column;

        li:not(:last-child) {
          margin-bottom: 15px;
        }
      }

      @media screen and (max-width: 767px) {
        flex-direction: row;

        li:not(:last-child) {
          margin-bottom: 0;
        }
      }

      li:not(:last-child) {
        margin-right: 20px;
      }

      a {
        font-size: 16px;
        line-height: 13px;
        font-weight: 700;
        color: inherit;
        text-decoration: none;
      }
    }

    &__social {
      display: flex;

      a {
        text-decoration: none;

        &:not(:last-child) {
          margin-right: 15px;
        }

        .icon {
          display: inline-flex;

          &.yt {
            .icon-link-yt(#3f65f1);
          }

          &.vk {
            .icon-link-vk(#3f65f1);
          }
        }
      }
    }

    &__topics {
      padding-top: 30px;
      padding-bottom: 40px;
      background-color: #f4f6fe;
    }

    .topics {
      &__header {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        cursor: pointer;

        h2 {
          margin: 0;
          font-size: 16px;
          font-weight: 700;
        }

        button {
          margin-left: 10px;
          background: none;
          padding: 0;

          &,
          &:focus {
            outline: none;
            border: none;
          }

          svg {
            transition: transform 250ms ease-in-out;
            transform: rotate(0deg);
          }
        }
      }

      &__toggler {
        transition: transform 250ms ease-in-out;
      }

      &.opened {
        .topics__toggler {
          transform: rotate(180deg);
        }
      }

      .topic {
        font-size: 13px;
        margin-bottom: 12px;
        a {
          color: #3f65f1;
        }
      }

      .topics-list {
        &__visible {
          .topic {
            &:nth-child(-n + 2) {
              display: block !important;
              height: auto !important;
              margin-bottom: 12px !important;
            }
          }
        }
      }

      &[data-hidden] {
        .topics__header {
          button {
            svg {
              transform: rotate(180deg);
            }
          }
        }
      }
    }

    &__contacts {
      .contact-phone {
        display: block;
        font-size: 16px;
        font-weight: 700;
        line-height: 18px;
        color: inherit;
        text-decoration: none;
        margin-bottom: 3px;
      }

      .contact-time {
        font-size: 13px;
        font-weight: 400;
        line-height: 14px;
        margin-bottom: 10px;

        br {
          display: none;

          @media screen and (max-width: 1080px) {
            display: inline;
          }

          @media screen and (max-width: 767px) {
            display: none;
          }
        }
      }
    }

    &__docs {
      a {
        text-decoration: none;
        margin-bottom: 8px;
        display: block;
      }
    }

    &__copyright {
      color: #808289;
    }

    @media screen and (min-width: 480px) {
      &__topics {
        .topics-list {
          column-count: 2;

          .topic {
            vertical-align: top;
          }

          &__visible {
            .topic {
              &:nth-child(-n + 6) {
                display: block !important;
                height: auto !important;
                margin-bottom: 12px !important;
              }
            }
          }
        }
      }
    }

    @media screen and (min-width: 768px) {
      padding-left: 30px;
      padding-right: 30px;

      &__info {
        display: flex;
      }

      .contact-time {
        span {
          white-space: nowrap;
        }
      }

      &__topics {
        .topics-list {
          column-count: 3;
        }
      }
    }

    @media screen and (min-width: 1024px) {
      &__menu {
        display: flex;
        flex-direction: column;
      }
    }
  }
}

.contact__social {
  display: inline-flex;

  @media screen and (max-width: 1080px) {
    display: flex;
  }

  @media screen and (max-width: 767px) {
    display: inline-flex;
  }

  a {
    text-decoration: none;

    &:not(:last-child) {
      margin-right: 15px;
    }

    .icon {
      display: inline-flex;
    }
  }
}

.contact__social--tg {
  .icon-link-tg(#3f65f1);
}

.contact__social--wa {
  .icon-link-wa(#3f65f1);
}

.contact__mail {
  margin-right: 20px;

  @media screen and (max-width: 1080px) {
    display: block;
    margin-bottom: 10px;
  }
  @media screen and (max-width: 767px) {
    display: inline;
    margin-bottom: 0;
  }
}

.contact__contacts-wrapper {
  display: flex;
  align-items: center;
  flex-direction: row;

  @media screen and (max-width: 1080px) {
    flex-direction: column;
    align-items: flex-start;
  }

  @media screen and (max-width: 767px) {
    flex-direction: row;
    align-items: center;
  }
}

.registry-label {
  &:hover {
    text-decoration: none;
  }

  .h1-label {
    display: flex;
    flex-direction: column;
    background-color: #E1E7FF;
    color: #3F65F1;
  
    text-transform: none;
  
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
    padding: 14px 15px;
  
    :first-child {
      margin-bottom: 7px;
    }
  
    :last-child {
      font-size: 15px;
      font-weight: 900;
      line-height: 15px;
      letter-spacing: 0.08em;
      text-transform: uppercase;
    }
  }
}

