.b-landing {
  h1.page-title {
    font-size: 60px;
    font-weight: 900;
    margin-bottom: 35px;
  }

  @media screen and (max-width: 991px) {
    h1.page-title {
      font-size: 40px;
    }
  }

  @media (max-width: 767px) {
    h1.page-title {
      font-size: 24px;
      margin-bottom: 20px;
    }
  }
}
