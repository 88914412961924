// новое место
.fb-sortable-ghost {
  background: #9bb0fb;
  height: 4px;

  * {
    display: none;
  }

  &.fb-sortable-disabled {
    background: #ffdddd;

    &[data-sortable-message] {
      position: relative;
      z-index: 200;

      &:before {
        content: attr(data-sortable-message);
        display: block;
        background-color: #f96261;
        color: white;
        font-size: 12px;
        padding: 10px 20px;
        border-radius: 8px;
        position: absolute;
        max-width: 250px;
        bottom: 100%;
        left: 50%;
        transform: translate(-50%, -10px);
      }

      &:after {
        content: "";
        display: block;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: #f96261;
        position: absolute;
        left: 50%;
        bottom: 100%;
        transform: translate(-5px, -5px);
      }
    }
  }
}

// перемещаемый элемент (.fb-sortable-clone)
.fb-sortable-clone {
  box-shadow: 0px 6px 15px rgba(63, 101, 241, 0.3);
  background: white;
  opacity: 1 !important;
  height: auto !important;
}
