:root {
  --f-color-primary: #3a5cdc;
  --f-color-primary--hover: #3553c1;
  --f-color-primary--contrast: #ffffff;

  --f-color-service: #73808d;
  --f-color-light: #a6b1bc;
  --f-color-text: #2e2f31;

  --f-color-danger: #f96261;
  --f-color-success: #37a74a;

  --f-color-yellow: #ffcc00;
  --f-color-blue: #2d99ff;
  --f-color-blue-2: #3f65f1;
  --f-color-violet: #8400ff;
  --f-color-teal: #16ceb9;
  --f-color-gold: #caad46;
  --f-color-mint: #24bbb6;
  --f-color-red: #f96261;

  --f-color-bg--body: #f5f6fa;
}

// colors
@f-color-yellow: #ffcc00;
@f-color-blue: #2d99ff;
@f-color-blue-2: #3f65f1;
@f-color-violet: #8400ff;
@f-color-teal: #16ceb9;
@f-color-gold: #caad46;
@f-color-mint: #24bbb6;
@f-color-red: #f96261;
@f-color-green: #37A74A;

// Base
@f-color-primary: #3a5cdc;
@f-color-primary--hover: #3553c1;
@f-color-primary--contrast: #ffffff;

@f-color-service: #73808d;
@f-color-service--hover: darken(@f-color-service, 15%);

@f-color-text: #2e2f31;
@f-color-text--hover: darken(#2e2f31, 5%);
@f-color-light: #a6b1bc;

// States
@f-color-danger: #f96261;
@f-color-danger--hover: darken(#f96261, 15%);
@f-color-success: #37a74a;
@f-color-success--hover: darken(#37a74a, 15%);

// Decor
@f-color-shadow: #cfd8dc;
@f-color-text-inactive: #cfd8dc;
@f-color-border: #eceff1;
@f-color-border--dark: #cfd8dc;
@f-color-table-border: #e7ebed;
@f-color-highlight: #f1f4ff;
@f-color-bg--body: #f5f6fa;
@f-color-bg--light: #fbfbfb;
@f-color-bg--service: #eceff1;

// Buttons
@f-btn-bg--default: #dadfe3;
@f-btn-hover--default: #c6cfd3;
@f-btn-color--default: @f-color-service;

@f-btn-bg--danger: #ffdddd;
@f-btn-hover--danger: #ffc0c0;
@f-btn-color--danger: @f-color-danger;

@f-btn-bg--success: #abefb7;
@f-btn-hover--success: #83ee9d;
@f-btn-color--success: @f-color-success;

@f-btn-bg--primary: @f-color-primary;
@f-btn-hover--primary: @f-color-primary--hover;
@f-btn-color--primary: @f-color-primary--contrast;

// links
@f-color-link: @f-color-primary;
@f-color-link--hover: @f-color-primary--hover;

// channels
@f-color-telegram: #1588c9;
@f-color-viber: #9151c1;

// rating
@rating-1-color: #f96261;
@rating-2-color: #f97f46;
@rating-3-color: #f4a224;
@rating-4-color: #aab540;
@rating-5-color: #3ece67;

// answers statuses
@answer-new-color: @f-color-teal;
@answer-in-process-color: @f-color-blue;
@answer-delayed-color: @f-color-red;
@answer-completed-color: @f-color-violet;
@answer-handle-color: @f-color-gold;

// coupons statuses
@coupon-total-color: @f-color-blue;
@coupon-sended-color: @f-color-violet;
@coupon-used-color: @f-color-primary;
@coupon-answers-color: @f-color-mint;
@coupon-available-color: @f-color-gold;

// nps
@nps-promoters-color: #00c968;
@nps-critics-color: @f-color-danger;

.colors() {
  primary: @f-color-primary;
  success: @f-color-success;
  danger: @f-color-danger;
  service: @f-color-service;
  text: @f-color-text;
  light: @f-color-light;

  yellow: @f-color-yellow;
  violet: @f-color-violet;
  teal: @f-color-teal;
  gold: @f-color-gold;
  blue: @f-color-blue;
  blue-2: @f-color-blue-2;
  mint: @f-color-mint;
  red: @f-color-red;
  green: @f-color-green;

  promoters: @nps-promoters-color;
  critics: @nps-critics-color;
}

.colored-statuses() {
  primary: @f-color-primary;
  gold: @f-color-gold;
  blue: @f-color-blue;
  mint: @f-color-mint;
  red: @f-color-red;
  violet: @f-color-violet;
  green: @f-color-green;

  goals: @f-color-gold;
  sended: @f-color-primary;
  not-sended: @f-color-violet;
  new: @f-color-teal;
  launched: @f-color-blue;
  stopped: @f-color-service;
}

.mailing-statuses() {
  sent: #3a5cdc;
  mail-open: #f96261;
  open: #8400ff;
  progress: #2d99ff;
  filled: #16ceb9;
}

@channels: {
  email: @f-color-service;
  sms: @f-color-service;
  telegram: #1588c9;
  viber: #9151c1;
  push: @f-color-service;
  link: @f-color-service;
};

.channels() {
  email: @f-color-service;
  sms: @f-color-service;
  telegram: #1588c9;
  viber: #9151c1;
  push: @f-color-service;
  link: @f-color-service;
};



/* Цвета иконок */
@icon-color-primary: @f-color-primary;
@icon-color-secondary: @f-color-service;
@icon-color-danger: @f-color-danger;
@icon-color-success: @f-color-success;
@icon-color-light: @f-color-light;
@icon-color-black: @f-color-text;
@icon-color-white: #ffffff;

/* Цвета кнопок */
@button-primary-color: @f-color-primary--contrast;
@button-primary-bg: @f-color-primary;
@button-primary-hover: @f-color-primary--hover;
@button-primary-text: @f-color-primary;
@button-primary-text-hover: @f-color-primary--hover;

@button-secondary-color: @f-color-service;
@button-secondary-bg: #dadfe3;
@button-secondary-hover: #c6cfd3;
@button-secondary-text: @f-color-service;
@button-secondary-text-hover: @f-color-text;

@button-danger-color: @f-color-danger;
@button-danger-bg: #ffdddd;
@button-danger-hover: #ffc0c0;
@button-danger-text: @f-color-danger;
@button-danger-text-hover: @f-color-danger--hover;

@button-success-color: @f-color-success;
@button-success-bg: #abefb7;
@button-success-hover: #83ee9d;
@button-success-text: @f-color-success;
@button-success-text-hover: @f-color-success--hover;

@button-white-color: @f-color-service;
@button-white-bg: #ffffff;
@button-white-hover: #C6CFD3;
@button-white-text:  @f-color-service;
@button-white-text-hover:  @f-color-text;

@button-black-color: #ffffff;
@button-black-bg: @f-color-text;
@button-black-hover: @f-color-text--hover;
@button-black-text: @f-color-text;
@button-black-text-hover: @f-color-text--hover;

