.b-landing__partners {
  overflow: hidden;
  margin-top: 80px;

  @media (max-width: 768px) {
    margin-top: 50px;
  }
}

.partners {
 
}
