.ugc {
  font-size: 13px;
  line-height: 1.4;

  h2 {
    font-size: 18px;
    font-weight: 900;
    margin: 30px 0 20px;
  }

  h3 {
    margin: 28px 0 20px;
  }

  p {
    margin: 20px 0;
    &.text--has-mini-header {
      margin-top: -11px;
      b {
        line-height: 36px;
      }
    }
  }

  blockquote {
    margin: 20px 0;
    font-style: italic;
  }

  b,
  strong {
    font-weight: bold;
  }

  i {
    font-style: italic;
  }

  p + ul,
  p + ol {
    margin-top: -5px;
  }

  ul {
    list-style: none;
  
    li {
      display: flex;
      margin-bottom: 15px;
      line-height: 1.3;

      &:before {
        content: "";
        display: block;
        width: 6px;
        height: 6px;
        transform: rotate(45deg);
        background: #b0c0ff;
        margin-right: 10px;
        margin-top: calc(1.3em - 14px);
        flex-shrink: 0;
        margin-left: 2px;
      }
    }

    &.list--inner {
      li {
        display: block;

        &:before {
          display: inline-block;
          vertical-align: top;
          margin-top: calc(1.3em - 15px);
        }
      }
      &.list--left-margin {
        margin-top: -16px;
        li {
          display: flex;
          line-height: 24px;
          margin-bottom: 15px;
          &:before {
            margin-top: 15px;
            margin-right: 8px;
            width: 6px;
            height: 6px;
          }
        }
      }
      &.list--left-margin-2 {
        margin-top: -16px;
        li {
          display: flex;
          line-height: 24px;
          margin-bottom: 15px;
          &:before {
            margin-top: 10px;
            margin-right: 8px;
            width: 6px;
            height: 6px;
          }
        }
      }
    }
  }

  ol {
    //list-style: inside decimal;
    list-style: decimal;
    list-style-position: outside;
    padding-left: 20px;
    li {
      margin-bottom: 15px;
      line-height: 1.3;

      ul{
        margin-top: 15px;
      }

      &::marker {
        font-weight: 700;
      }
    }
  }


  @media screen and (min-width: 768px) {
    font-size: 17px;

    h2 {
      font-size: 26px;
      margin: 35px 0 25px;
    }

    p {
      margin: 25px 0;
    }

    p + ul,
    p + ol {
      margin-top: -10px;
    }

    blockquote {
      margin: 30px 0;
    }
  }
}
