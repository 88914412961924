.f-input-group {
  position: relative;

  &__prepend {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 48px;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__prepend ~ .form-control {
    padding-left: 50px;
  }

  &__append {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 48px;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__append ~ .form-control {
    padding-right: 50px;
  }
}
