@import '../vars/typography.less';


.f-fs-0 {
  font-size: 0!important;
}
.f-fs-1 {
  font-size: @f-fs-1!important;
}
.f-fs-1-5 {
  font-size: @f-fs-1-5!important;
}
.f-fs-2 {
  font-size: @f-fs-2!important;
}
.f-fs-2-5 {
  font-size: @f-fs-2-5!important;
}
.f-fs-3 {
  font-size: @f-fs-3!important;
}

.semibold, .font-weight-500 {
  font-weight: 500!important;
}
.font-weight-700 {
  font-weight: 700!important;
}

.no-link {
  &:hover {
    text-decoration: none!important;
  }
}

/*
22\900 roboto 212f31
Заголовок страницы
Редактируемое имя
*/

h1,
.f-h1 {
  font-size: @f-fs-h1;
  font-weight: 900;
}

h2,
.f-h2 {
  font-size: @f-fs-h2;
  font-weight: bold;
}

h3 {
  font-size: 1.25em;
  font-weight: bold;
}

h4 {
  font-size: 1em;
  font-weight: bold;
}

h5, h6 {
  font-size: 0.75em;
  font-weight: bold;
}

.only-mobile({
  .f-h1 {
    font-size: @f-fs-h1-sm;
  }
});

.nowrap {
  white-space: nowrap;
}

.overflow-ellipsis {
  text-overflow: ellipsis;
}
.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
}


.empty-section-message {
  font-size: 14px;
  text-align: center;
  font-weight: normal;
  color: @f-color-text;
  line-height: 1.2;
}
