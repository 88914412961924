:root {
  --foquz-side-offset: 20px;
  --foquz-dialog-z-index: 1050;
}

.only-mobile({
  :root {
    --foquz-side-offset: 15px;
  }
});

.app {
  display: flex;
  min-height: 100%;
  width: 100%;
  flex-direction: column;
  min-width: 992px;
  position: relative;

  .app-message {
    position: sticky;
    top: 0;
    left: 0;
    right: 0;

    z-index: 1001;

    &.app-message--warning {
      background-color: #ffdddd;
    }

    &.app-message--info {
      background-color: #d9e0fc;
    }

    &__content {
      font-size: 13px;
      font-weight: normal;
      line-height: 1.2;
      padding-right: 50px;
      min-height: 40px;
      display: flex;
      align-items: center;
      padding-left: 25px;
      padding-top: 12px;
      padding-bottom: 12px;
    }

    &__close {
      position: absolute;
      top: 16px;
      right: 16px;
      font-size: 0;
      cursor: pointer;
      .f-icon {
        width: 10px;
        height: 10px;
        svg {
          width: 10px;
          height: 10px;
        }
      }
    }
  }

  .app-message + .app__body {
    .app__sidebar {
      .sidebar {
        top: 40px;
      }
    }
  }

  .foquz-app-header {
    padding-bottom: 16px;

    .btn-menu {
      margin-right: 32px;
    }

    &__menu {
      display: none;
      margin-left: 24px;
      margin-right: 24px;
    }

    .userpic {
      margin-right: 8px;
    }

    .only-mobile({
      &__menu {
        display: flex;
        align-items: center;
      }
      .userpic {
        margin-right: 0;
      }
      &__user-data {
        display: none;
      }
    });
  }

  &__sidebar {
    width: var(--foquz-sidebar-width);
    transition: all 0.5s ease-in-out;
    flex-shrink: 0;
    z-index: 1000;
    position: relative;

    .sidebar {
      position: sticky;
      top: 0;
    }

    &--small {
      width: 108px;
    }
  }

  &__body {
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-grow: 1;

    max-width: 1600px;
    margin-left: auto;
    margin-right: auto;
    align-self: center;
  }

  &__content {
    flex-grow: 1;
    width: 0;
    padding: 25px var(--foquz-side-offset) 56px 0;
  }

  &__footer {
    display: flex;
    width: 100%;
    flex-shrink: 0;
    flex-grow: 0;

    background-color: rgba(63, 101, 241, 0.03);
    .footer {
      max-width: 1600px;
      margin-left: auto;
      margin-right: auto;
      align-self: center;
      background: none;
    }
  }

  .only-desktop({
    .foquz-app-header {
      display: none;
    }
  });

  .mobile-and-tablet({
    min-width: 0;

		&__sidebar {
			display: none;
		}
		&__content {
			padding-left: 0;
      padding-right: 0;
      padding-top: 16px;
      padding-bottom: 0;
		}
    &__inner-content {
      padding-left: var(--foquz-side-offset);
      padding-right: var(--foquz-side-offset);
    }
    .foquz-app-header {
      padding-left: var(--foquz-side-offset);
      padding-right: var(--foquz-side-offset);
    }
	});

  .only-mobile({
    &__content {
      padding-top: 10px;
    }
    .foquz-app-header {
      padding-bottom: 8px;
    }
  });

  &--initializing {
    visibility: hidden;
  }
}
