.b-landing__features {
  margin-bottom: 110px;
}

.features {
  position: relative;
  .feature {
    padding-left: 100px;
    padding-right: 60px;
    font-size: 15px;
    line-height: 1.3;

    &-item {
      max-width: 305px;
      margin-bottom: 24px;
      font-size: 17px;

      &__icon {
        margin-bottom: 12px;
      }
    }

    .list {
      font-size: 17px;
    }

    &__description {
      margin-bottom: 15px;
    }

    &__container {
      display: flex;
      align-items: center;
    }

    &__title {
      font-size: 40px;
      font-weight: 700;
      margin-bottom: 15px;
      line-height: 1;
    }

    &[data-feature='keys'] {
      img {
        width: 446px;
      }
    }
    &[data-feature='clients'] {
      img {
        width: 405px;
      }
    }
    &[data-feature='stats'] {
      img {
        width: 611px;
      }
      .feature__container {
        align-items: start;
      }

      .feature__title {
        margin-bottom: 30px;
      }

      .feature-item__text {
        max-width: 280px;
      }
    }
    &[data-feature='automatization'] {
      img {
        width: 406px;
      }
    }
    &__content {
      flex-grow: 1;
    }
  }
  .swiper-navigation {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    display: flex;
    justify-content: space-between;
    z-index: 2;

    &__button {
      height: 100%;
      pointer-events: all;
      width: 45px;
      display: flex;
      padding: 0;

      &,
      &:focus,
      &:active,
      &:hover {
        background: transparent;
        border: none;
        outline: none;
        box-shadow: none;
      }
      svg {
        margin-top: 205px;
      }
    }
  }
}

@media (max-width: @breakpointXl) {
  .b-landing__features {
    margin-bottom: 5px;
  }
  .features {
    margin-left: -30px;
    margin-right: -30px;
    .feature {
      padding-left: 30px;
      padding-right: 0px;
      overflow: hidden;

      &__title {
        width: 500px;
      }

      &__container {
        align-items: flex-start;
      }

      &__content {
        min-width: 350px;
        flex-shrink: 1;
      }

      &[data-feature='stats'] {
        .feature__content {
          min-width: 305px;
        }
      }

      &[data-feature='keys'] {
        .feature__content {
          padding-right: 10px;
        }
        .feature__img {
          padding-top: 21px;
          padding-left: 20px;
        }
      }

      &[data-feature='automatization'] {

        .feature__img {
          padding-top: 56px;
          padding-left: 20px;
        }
      }
    }
    .swiper-navigation {
      justify-content: flex-end;
      padding-top: 13px;
      padding-right: 30px;
      &__button {
        height: auto;
        padding-left: 30px;
        width: 75px;
        align-self: start;
        svg {
          margin-top: 0;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .b-landing__features {
    margin-bottom: 0px;
  }
  .features {
    margin-left: -20px;
    margin-right: -20px;
    .feature {
      padding-left: 20px;
      padding-right: 20px;

      &-item {
        font-size: 15px;
      }

      &__title {
        width: 100%;
        font-size: 25px;
        padding-right: 100px;
        position: relative;
        z-index: 2;
      }
      &__img {
        display: none;
      }
      .list {
        font-size: 15px;
      }
      &__content {
        min-width: 0;
        max-width: 100%;
      }
    }
    .swiper-navigation {
      padding-top: 10px;
      padding-right: 20px;
      &__button {
        width: 51px;
      }
    }
  }
}
