.f-select {
  .select2 {
    .selection {
      display: inline;
      width: 0px;
      overflow: hidden;
    }
    .select2-selection {
      visibility: hidden;
    }
  }

}

.select2-container {
  .select2-dropdown.f-select__dropdown {
    .select2-results {
      &__option {
        &[aria-disabled="true"] {
          //display: none;
        }
      }
    }
  }
  .select2-selection {
    background: none!important;
  }
}
