:root {
  --foquz-content-width: 1600px;
  --foquz-sidebar-width: 110px;
  --foquz-content-offset: calc(50vw - (var(--foquz-content-width) / 2));
}

body.sidebar-open {
  --foquz-sidebar-width: 240px;
}



.min-w-100 {
  min-width: 100%;
}

.f-filters-group {
  display: flex;
  flex-wrap: wrap;

  &__filter {
    margin-right: 30px;
  }
}

.info-row {
  display: flex;
  padding: 0 5px;

  &__section {
    padding: 10px 15px;
    border-right: 1px solid @f-color-border;
    align-items: center;
    display: flex;

    &:last-child {
      border-right: none;
    }
  }
  &__label {
    font-weight: 500;
    font-size: 14px;
    color: @f-color-service;
  }

  &--lg {
    .info-row__section {
      padding: 15px;
    }
    .info-row__search {
      padding: 0;
    }
  }
  &__search {
    padding: 0;

    .f-input-group {
      width: 100%;
      height: 100%;

      .form-control {
        height: 100%;
        width: 100%;
        border-radius: 0;
        border-color: transparent;

        &:focus {
          border-color: @f-color-primary;
          box-shadow: 0 0 5px rgba(63, 101, 241, 0.5);
        }
      }
    }
  }
}



@import '_app.less';
@import '_card.less';
@import '_tabs.less';
@import '_scroll.less';
@import '_spacing.less';
@import '_display.less';
