.f-scale {
  display: inline-flex;
  border: 1px solid #CFD8DC;
  border-radius: 4px;
  overflow: hidden;

  &__item {
    width: 48px;
    height: 48px;
    flex-grow: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: #2E2F31;
    transition: background 300ms ease-in-out;
    font-size: 16px;
    font-weight: 500;

    &:not(:first-child) {
      border-left: 1px solid #CFD8DC;
    }

    &--active {
      background: #F1F5F6;
      color: #73808D;
    }
  }
}
